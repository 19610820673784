import {
    DialogTitle,
    Modal,
    ModalClose,
    ModalDialog,
    Link,
    Typography,
    Select,
    Option,
    FormLabel,
    FormHelperText,
    Slider,
    Switch
} from "@mui/joy";
import React, {useEffect} from "react";
import Button from "@mui/joy/Button";
import {CreateStripePaymentMethod, PurchaseLeads, SendRotaEmail} from "../../axios/Axios";
import {toast} from "react-toastify";
import DialogActions from '@mui/joy/DialogActions';
import Divider from "@mui/joy/Divider";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import DialogContent from "@mui/joy/DialogContent";


export default function StripeLeadPaymentModal({openState, franchise, parentCallback, selectedAd, selectedAdToggled}) {
    let location = localStorage.getItem('location')?.toLowerCase().replace(' ', '_')
    const [open, setOpen] = React.useState(openState);
    const [loadingUrl, setLoadingUrl] = React.useState(false);
    const [toggleOffReason, setToggleOffReason] = React.useState<String>('stopping-operations');
    const [value, setValue] = React.useState<number>(75)
    const formatGBP = (amount) => {
        return new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'GBP',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(amount);
    };

    function closeModal() {
        setOpen(false)
        parentCallback('')
    }

    async function purchaseLeads() {
        turnAdsOn()
        let url = await PurchaseLeads(franchise.location, value, true)
        if (url) {
            window.location.replace(url['payment_link']);
        }
    }

    function toggleOffAds() {
        parentCallback(selectedAd, false, toggleOffReason)
    }


    useEffect(() => {
        setOpen(openState)
    }, [openState]);

    async function addPaymentMethod() {
        setLoadingUrl(true)
        let link = await CreateStripePaymentMethod(franchise.location, (75 - franchise.lead_balance))
        if (link) {
            window.location.replace(link['url']);
        }
    }

    function turnAdsOn() {
        parentCallback(selectedAd, true)
    }

    return (
        <>
            <Modal slotProps={{
                backdrop: {
                    sx: {
                        backdropFilter: 'none',
                        backgroundColor: 'rgb(108 108 108 / 60%)',
                        padding: '20px'
                    },
                },
            }} open={open} onClose={() => closeModal()}>
                <ModalDialog>
                    <ModalClose></ModalClose>
                    {selectedAdToggled == 'Off' ? (
                        <>
                            <DialogTitle>Toggle {selectedAd.replace('_', ' ')} ads on</DialogTitle>
                            {franchise.lead_balance < 75 ? (
                                <>
                                    <DialogContent sx={{maxWidth: '400px'}}>
                                        Your current balance is £{franchise.lead_balance}.0. You need
                                        £{75 - franchise.lead_balance}.0 in order to
                                        turn your ads on.
                                    </DialogContent>
                                    <Slider
                                        value={value}
                                        onChange={(event: Event, newValue: number | number[]) => {
                                            setValue(newValue as number);
                                        }}
                                        min={75 - franchise.lead_balance}
                                        max={450}
                                        step={1}
                                        valueLabelDisplay="on"
                                        valueLabelFormat={formatGBP}
                                        sx={{marginTop: 2}}
                                    />
                                </>


                            ) : (
                                <DialogContent sx={{maxWidth: '400px'}}>
                                    Your current balance is £{franchise.lead_balance}.0.
                                </DialogContent>

                            )}
                            {!franchise.stripe_customer_payment_method_linked && (
                                <DialogContent sx={{maxWidth: '400px'}}>
                                    <Typography color="danger" sx={{marginTop: '10px', textAlign: 'center'}}>
                                        You don't have a payment method linked.
                                    </Typography>
                                    <Button size="lg" loading={loadingUrl} onClick={addPaymentMethod}
                                            variant="plain">Click here to
                                        add a payment method</Button>
                                </DialogContent>
                            )}

                            <DialogActions>
                                {franchise.lead_balance < 75 ? (
                                    <Button disabled={!franchise.stripe_customer_payment_method_linked} variant="solid"
                                            color="success" onClick={() => purchaseLeads()}>
                                        Turn on & top up balance with {formatGBP(value)}
                                    </Button>
                                ) : (
                                    <Button disabled={!franchise.stripe_customer_payment_method_linked} variant="solid"
                                            color="success" onClick={() => turnAdsOn()}>
                                        Turn On
                                    </Button>
                                )}
                                <Button variant="plain" color="neutral" onClick={() => closeModal()}>
                                    Cancel
                                </Button>
                            </DialogActions>
                        </>
                    ) : (
                        <>
                            <DialogTitle
                                sx={{padding: '10px'}}
                                color="danger"><WarningRoundedIcon/>Toggle {selectedAd.replace('_', ' ')} ads off
                            </DialogTitle>
                            <DialogContent sx={{marginTop: '5px', marginBottom: '5px'}}>
                                <FormLabel>Reason For Turning Off</FormLabel>
                                <Select onChange={(e, newValue) => {
                                    if (newValue) {
                                        setToggleOffReason(newValue)
                                    }
                                }} defaultValue="stopping-operations">
                                    <Option value="stopping-operations">Temporarily Stopping Operations</Option>
                                    <Option value="not-enough-leads">Not Enough Leads</Option>
                                    <Option value="too-many-leads">Too Many Leads At Once</Option>
                                </Select>
                                <FormHelperText sx={{color: 'red'}}>Regularly turning ads off and on can worsen
                                    performance of
                                    ads over time</FormHelperText>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="solid" color="danger" onClick={() => toggleOffAds()}>
                                    Turn Off
                                </Button>
                                <Button variant="plain" color="neutral" onClick={() => closeModal()}>
                                    Cancel
                                </Button>
                            </DialogActions>
                        </>

                    )}

                </ModalDialog>
            </Modal>
        </>
    )

}