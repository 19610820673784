import {
    Breadcrumbs,
    Card,
    CircularProgress,
    CssBaseline,
    CssVarsProvider,
    Input,
    Link,
    ListItem,
    List,
    Checkbox,
    Button
} from "@mui/joy";
import Box from "@mui/joy/Box";
import Header from "../dashboard-components/Header";
import SideNav from "../dashboard-components/SideNav";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import Typography from "@mui/joy/Typography";
import React from 'react';
import {useEffect, useState} from "react";
import {GetFranchiseByName, GetToggledAds, PostFranchiseSettings, GetFranchiseNotifications} from "../../axios/Axios";
import FormLabel from "@mui/joy/FormLabel";
import DatePicker from "react-datepicker";
import {GetBookingExemptDates} from "../../axios/Bookings-Axios";
import {toast, ToastContainer} from "react-toastify";

interface Notifications {
}

interface Franchise {
    notifications: Notifications;
    location: string;
    owner: string;
    email: string;
    phone: string;
}


export default function FranchiseSettings() {
    const [selectedDates, setSelectedDates] = useState([]);

    const [loadedData, setLoadingData] = React.useState(true);

    const [franchiseNotifications, setFranchiseNotifications] = useState<FranchiseNotifications>({
        email_booking_created: false,
        email_lead_balance: false,
        email_lead_created: false,
        email_message_created: false,
        franchise: undefined,
        sms_booking_created: false,
        sms_lead_balance: false,
        sms_lead_created: false,
        sms_message_created: false,
        id: "",
    });
    const [franchise, setFranchise] = useState<Franchise>({
        email: "",
        owner: "",
        phone: "",
        location: "",
        notifications: {}
    });

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, field: keyof FranchiseNotifications) => {
        setFranchiseNotifications(prevState => ({
            ...prevState,
            [field]: event.target.checked
        }));
    };

    useEffect(() => {
        setLoadingData(true)
        let location = localStorage.getItem('location')?.toLowerCase().replace(' ', '_')
        const getFranchise = async () => {
            let exempt_dates = await GetBookingExemptDates(location);
            setSelectedDates(exempt_dates.map(item => item.date))
            let franchise_response = await GetFranchiseByName(location);
            setFranchise(franchise_response)

            let franchise_notifications_response = await GetFranchiseNotifications(location);
            setFranchiseNotifications(franchise_notifications_response)
            setLoadingData(false)
        };
        getFranchise();

    }, [])

    const isWeekdayAndInPast = date => {
        let today = new Date();
        today = new Date(today.setDate(today.getDate() + 3));
        today.setHours(0, 0, 0, 0);
        date.setHours(0, 0, 0, 0);

        const day = date.getDay();
        const isWeekday = day !== 0 && day !== 6;
        const isInPast = date > today;

        return isWeekday && isInPast;
    };


    const updateFranchise = () => {
        let location = localStorage.getItem('location')?.toLowerCase().replace(' ', '_')

        const updateFranchise = async () => {
            let franchise_response = await PostFranchiseSettings(location, franchise, selectedDates, franchiseNotifications);
            toast.success('Saved Franchise Settings!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        };
        updateFranchise();

    }
    const onChange = (dates) => {
        setSelectedDates(dates);
    };

    return (
        <CssVarsProvider disableTransitionOnChange>
            <CssBaseline/>
            <ToastContainer/>
            <Box sx={{display: 'flex', minHeight: '100dvh'}}>
                <Header/>
                <SideNav/>
                <Box
                    component="main"
                    className="MainContent"
                    sx={{
                        px: {xs: 2, md: 6},
                        pt: {
                            xs: 'calc(12px + var(--Header-height))',
                            sm: 'calc(12px + var(--Header-height))',
                            md: 3,
                        },
                        pb: {xs: 2, sm: 2, md: 3},
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 0,
                        height: '100dvh',
                        gap: 1,
                    }}
                >
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Breadcrumbs
                            size="sm"
                            aria-label="breadcrumbs"
                            separator={<ChevronRightRoundedIcon/>}
                            sx={{pl: 0}}
                        >
                            <Link
                                underline="none"
                                color="neutral"
                                href="#some-link"
                                aria-label="Home"
                            >
                                <HomeRoundedIcon/>
                            </Link>
                            <Link
                                underline="hover"
                                color="neutral"
                                href="#some-link"
                                fontSize={12}
                                fontWeight={500}
                            >
                                Dashboard
                            </Link>
                            <Typography color="primary" fontWeight={500} fontSize={12}>
                                Franchise Settings
                            </Typography>
                        </Breadcrumbs>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            mb: 1,
                            gap: 1,
                            flexDirection: {xs: 'column', sm: 'row'},
                            alignItems: {xs: 'start', sm: 'center'},
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography level="h2" component="h1">
                            {franchise.location} Franchise Settings
                        </Typography>
                    </Box>

                    {loadedData ? (
                        <Box sx={{
                            display: 'flex',
                            gap: 2,
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            minWidth: '50%',
                            height: '100%',
                            justifyContent: 'center'
                        }}>
                            <CircularProgress
                                size="lg"
                            />
                        </Box>
                    ) : (
                        <Card variant={"plain"}>
                            <Box sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                                <Box sx={{width: '50%', flex: '48%'}}>
                                    <Typography level="h4">
                                        Information
                                    </Typography>
                                    <Box>
                                        <FormLabel>Owner Name</FormLabel>
                                        <Input onChange={(event) => {
                                            setFranchise(prevFranchise => ({
                                                ...prevFranchise,
                                                owner: event.target.value
                                            }));
                                        }} sx={{background: 'white', width: '80%'}} variant="outlined"
                                               value={franchise.owner}></Input>
                                        <FormLabel sx={{marginTop: '5px'}}>Phone Number</FormLabel>
                                        <Input onChange={(event) => {
                                            setFranchise(prevFranchise => ({
                                                ...prevFranchise,
                                                phone: event.target.value
                                            }));
                                        }} sx={{background: 'white', width: '80%'}} variant="outlined"
                                               value={franchise.phone}></Input>
                                        <FormLabel sx={{marginTop: '5px'}}>Email</FormLabel>
                                        <Input onChange={(event) => {
                                            setFranchise(prevFranchise => ({
                                                ...prevFranchise,
                                                email: event.target.value
                                            }));
                                        }} sx={{background: 'white', width: '80%'}} variant="outlined"
                                               value={franchise.email}></Input>
                                    </Box>
                                </Box>

                                <Box sx={{width: '50%', flex: '48%'}}>
                                    <Typography level="h4">
                                        Notifications
                                    </Typography>
                                    <Box sx={{display: 'flex', width: '500px'}}>
                                        <List size="sm" sx={{width: '50%'}}>
                                            <Typography level="body-sm" color={"neutral"}>New Leads</Typography>
                                            <ListItem>
                                                <Checkbox
                                                    label="Email"
                                                    checked={franchiseNotifications.email_lead_created}
                                                    onChange={(e) => handleCheckboxChange(e, 'email_lead_created')}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <Checkbox
                                                    label="Text message"
                                                    checked={franchiseNotifications.sms_lead_created}
                                                    onChange={(e) => handleCheckboxChange(e, 'sms_lead_created')}
                                                />
                                            </ListItem>
                                        </List>

                                        <List size="sm" sx={{width: '50%'}}>
                                            <Typography level="body-sm" color={"neutral"}>New Bookings</Typography>
                                            <ListItem>
                                                <Checkbox
                                                    label="Email"
                                                    checked={franchiseNotifications.email_booking_created}
                                                    onChange={(e) => handleCheckboxChange(e, 'email_booking_created')}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <Checkbox
                                                    label="Text message"
                                                    checked={franchiseNotifications.sms_booking_created}
                                                    onChange={(e) => handleCheckboxChange(e, 'sms_booking_created')}
                                                />
                                            </ListItem>
                                        </List>
                                    </Box>

                                    <Box sx={{display: 'flex', width: '500px'}}>
                                        <List size="sm" sx={{width: '50%'}}>
                                            <Typography level="body-sm" color={"neutral"}>New Customer
                                                Messages</Typography>
                                            <ListItem>
                                                <Checkbox
                                                    label="Email"
                                                    checked={franchiseNotifications.email_message_created}
                                                    onChange={(e) => handleCheckboxChange(e, 'email_message_created')}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <Checkbox
                                                    label="Text message"
                                                    checked={franchiseNotifications.sms_message_created}
                                                    onChange={(e) => handleCheckboxChange(e, 'sms_message_created')}
                                                />
                                            </ListItem>
                                        </List>

                                        <List size="sm" sx={{width: '50%'}}>
                                            <Typography level="body-sm" color={"neutral"}>Lead Generation Balance
                                                Low</Typography>
                                            <ListItem>
                                                <Checkbox
                                                    label="Email"
                                                    checked={franchiseNotifications.email_lead_balance}
                                                    onChange={(e) => handleCheckboxChange(e, 'email_lead_balance')}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <Checkbox
                                                    label="Text message"
                                                    checked={franchiseNotifications.sms_lead_balance}
                                                    onChange={(e) => handleCheckboxChange(e, 'sms_lead_balance')}
                                                />
                                            </ListItem>
                                        </List>
                                    </Box>
                                </Box>

                                <Box sx={{width: '50%', flex: '48%'}}>
                                    <Typography level="h4">
                                        Booking Date Availability
                                    </Typography>
                                    <Typography sx={{marginTop: '5px', marginBottom: '10px'}} level={"body-md"}
                                                color={"neutral"}>
                                        Select dates you <Typography fontWeight={500} level={"body-md"}
                                                                     color={"danger"}>DONT</Typography> want the
                                        customer
                                        to be able to book in for.
                                    </Typography>
                                    <DatePicker
                                        inline
                                        filterDate={isWeekdayAndInPast}
                                        selectedDates={selectedDates}
                                        selectsMultiple
                                        onChange={onChange}
                                        shouldCloseOnSelect={false}
                                        disabledKeyboardNavigation
                                    />
                                </Box>

                            </Box>
                            <Box sx={{marginLeft: 'auto', marginRight: '10px'}}>
                                <Button color={"success"} onClick={() => {
                                    updateFranchise()
                                }}>Save Franchise</Button>
                            </Box>

                        </Card>

                    )}

                </Box>
            </Box>
        </CssVarsProvider>
    )

}