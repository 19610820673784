import React, {useEffect} from "react";
import {
    CircularProgress,
    ListItemDecorator,
    Modal,
    ModalClose,
    ModalDialog,
    Radio,
    RadioGroup,
    Select, Switch,
    Textarea
} from "@mui/joy";
import Box from "@mui/joy/Box";
import {
    CreateBooking, CreateLead, CreateRecurringBooking,
    GetAllBookings,
    GetAllServicePrices, GetBookingById,
    GetRecurringBookingById,
    UpdateBooking, UpdateLead, UpdateRecurringBooking
} from "../../axios/Bookings-Axios";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Option from "@mui/joy/Option";
import Typography from "@mui/joy/Typography";
import 'react-datepicker/dist/react-datepicker.css';
import Button from "@mui/joy/Button";
import DatePicker from "react-datepicker";
import CustomersTopComponent from "./CustomersTopComponent";


function formatDate(old_date) {
    let date = new Date(old_date);

    if (!isNaN(date.getTime())) {
        return date.toLocaleString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        });
    } else {
        return 'No Valid Date';
    }
}

export default function RecurringBookingsModal({
                                                   openState,
                                                   recurringBookingId,
                                                   parentCallback
                                               }) {
    const [open, setOpen] = React.useState(openState);
    const [loadedData, setLoadedData] = React.useState(false);

    const [customer, setCustomer] = React.useState <Customer>({
        advertisement_platform: undefined,
        created_at: "",
        email: "",
        full_name: "",
        house_type: {name: 'Detached', id: 1},
        id: 0,
        notes: "",
        phone: "",
        postcode: "",
        street_address: ""
    });
    const [recurringBooking, setRecurringBooking] = React.useState <RecurringBooking>({
        active: true,
        created_at: new Date().toISOString(),
        start_date: new Date().toISOString(),
        customer: customer,
        frequency: 'week',
        interval: "4",
        id: -1,
        service: {id: 0, name: "Window Clean"},
        service_price: {
            id: 0,
            service: {id: 0, name: "Window Clean"},
            price: 15,
        }
    });

    const handleServiceChange = (newValue: string | null) => {
        if (newValue !== null) {
            const isWindowClean = newValue === 'Window Clean';
            const frequency = isWindowClean ? 'week' : 'month';
            const interval = isWindowClean ? "4" : "6";
            setRecurringBooking(prevCustomer => ({
                ...prevCustomer,
                service: {name: newValue, id: -1},
                frequency: frequency,
                interval: interval
            }));
        }
    };

    useEffect(() => {
        setRecurringBooking(prevBooking => ({
            ...(prevBooking || {}),
            customer: customer
        }));
    }, [customer]);

    useEffect(() => {
        if (recurringBookingId === 0) {
            return;
        }
        setLoadedData(false)
        const fetchData = async () => {
            if (recurringBookingId === -1) {
                setLoadedData(true)
                return
            }

            let recurringBookingLoaded = await GetRecurringBookingById(recurringBookingId);
            if (recurringBookingLoaded['customer']['notes'] == null) {
                recurringBookingLoaded['customer']['notes'] = ''
            }
            setCustomer(recurringBookingLoaded['customer'])
            setRecurringBooking(recurringBookingLoaded)
            setLoadedData(true)
        };
        fetchData()
    }, []);


    function closeModal() {
        setOpen(false)
        parentCallback();
    }

    const handleSave = async (event) => {
        event.preventDefault()
        const updateSavingLead = async () => {
            if (recurringBooking.id !== -1) {
                return await UpdateRecurringBooking(recurringBookingId, recurringBooking);
            } else {
                return await CreateRecurringBooking(recurringBooking);
            }
        };

        await updateSavingLead();
        window.location.reload(false);
    };


    function setChecked(checked: boolean) {
        setRecurringBooking(prevBooking => ({
            ...(prevBooking || {}),
            active: checked
        }));
    }

    const handleTimeChange = (newValue: string | null) => {
        if (newValue !== null) {
            let interval = newValue.split(' ')[0]
            let frequency = newValue.split(' ')[1]
            setRecurringBooking(prevBooking => ({
                ...(prevBooking || {}),
                frequency: frequency,
                interval: interval
            }));

        }
    };


    const handleColor = (time) => {
        return time.getHours() > 12 ? "" : "text-error";
    };

    function getDefaultTiming() {
        return recurringBooking.interval + ' ' + recurringBooking.frequency
    }

    const handleChangeRecurringBooking = (event) => {
        const {name, value} = event.target;
        setRecurringBooking(prevBooking => ({
            ...(prevBooking || {}),
            service_price: {
                id: 0,
                service: prevBooking.service,
                price: value,
            },
        }));

    };


    function getNextBookingCalculate() {
        let date = new Date()
        if (recurringBooking.start_date != null) {
            date = new Date(recurringBooking?.start_date);
        }

        switch (recurringBooking.frequency) {
            case 'week':
                date.setDate(date.getDate() + (Number(recurringBooking.interval) * 7));
                break;
            case 'month':
                date.setMonth(date.getMonth() + Number(recurringBooking.interval));
                break;
            case 'year':
                date.setFullYear(date.getFullYear() + Number(recurringBooking.interval));
                break;
        }
        return formatDate(date.toISOString());
    }

    return (
        <>
            <Modal slotProps={{
                backdrop: {
                    sx: {
                        backdropFilter: 'none',
                        backgroundColor: 'rgb(108 108 108 / 60%)',
                        padding: '10px'
                    },
                },
            }} open={open} onClose={() => closeModal()}>
                <ModalDialog sx={{overflowY: 'scroll'}}>
                    <form onSubmit={handleSave} id="a-form">
                        <ModalClose/>
                        <Box sx={{
                            minWidth: 600,
                            display: 'flex',
                            flexDirection: 'column',
                            overflowX: 'hidden',
                        }}>
                        </Box>

                        {!loadedData ?
                            <Box sx={{
                                display: 'flex',
                                gap: 2,
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                minWidth: '50%',
                                height: '100%',
                                justifyContent: 'center'
                            }}>
                                <CircularProgress
                                    size="lg"
                                />
                            </Box>
                            :
                            <Box>
                                <Typography color={"danger"} sx={{marginBottom: '5px', width: '100%'}}>* A job will run
                                    everyday at
                                    midnight to make sure 10 bookings exist for this recurring booking.</Typography>

                                <CustomersTopComponent customer={customer} setCustomer={setCustomer}/>


                                <Box sx={{marginTop: '10px'}}>
                                    <Typography variant="soft" sx={{marginBottom: '5px', width: '100%'}}>Service
                                        Selected</Typography>
                                    <Box>
                                        <Select
                                            onChange={(e, newValue) => {
                                                handleServiceChange(newValue);
                                            }}

                                            defaultValue={recurringBooking.service.name}>
                                            <Option value="Window Clean">Window Clean</Option>
                                            <Option value="Gutter Clean">Gutter Clean</Option>
                                            <Option value="Conservatory Gutter Clean">Conservatory Gutter Clean</Option>
                                        </Select>
                                    </Box>
                                </Box>

                                <Box sx={{marginTop: '10px'}}>
                                    <Typography variant="soft" sx={{marginBottom: '5px', width: '100%'}}>Recurring
                                        Time</Typography>
                                    <Box>
                                        <Select
                                            onChange={(e, newValue) => {
                                                handleTimeChange(newValue);
                                            }}
                                            value={getDefaultTiming()}>
                                            {recurringBooking.service.name == "Window Clean" ? (
                                                <>
                                                    <Option value="2 week">Every 2 Weeks</Option>
                                                    <Option value="4 week">Every 4 Weeks</Option>
                                                    <Option value="6 week">Every 6 Weeks</Option>
                                                    <Option value="8 week">Every 8 Weeks</Option>
                                                    <Option value="12 week">Every 12 Weeks</Option>
                                                </>
                                            ) : (
                                                <>
                                                    <Option value="3 month">Every 3 Months</Option>
                                                    <Option value="4 month">Every 4 Months</Option>
                                                    <Option value="6 month">Every 6 Months</Option>
                                                    <Option value="12 month">Every 12 Months</Option>
                                                </>
                                            )}
                                        </Select>
                                    </Box>
                                </Box>
                                <Box sx={{marginTop: '10px'}}>
                                    <Typography variant="soft" sx={{marginBottom: '5px', width: '100%'}}>Service
                                        Price</Typography>
                                    <Box>
                                        <Input
                                            startDecorator={'£'}
                                            type="number"
                                            name={'gutterCleanPrice'}
                                            value={recurringBooking?.service_price?.price}
                                            onChange={handleChangeRecurringBooking}
                                        />
                                    </Box>
                                </Box>
                                <Box sx={{marginTop: '10px'}}>
                                    <Typography variant="soft" sx={{marginBottom: '5px', width: '100%'}}>Start
                                        Date</Typography>
                                    <DatePicker
                                        showTimeSelect
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        selected={new Date(String(recurringBooking?.start_date))}
                                        onChange={(date) => {
                                            setRecurringBooking(prevBooking => ({
                                                ...prevBooking,
                                                start_date: date.toISOString()
                                            }));
                                        }}
                                        timeClassName={handleColor}
                                    />
                                    <Typography sx={{marginTop: '5px', width: '100%'}}>The next booking will be created
                                        on {getNextBookingCalculate()}</Typography>
                                </Box>


                            </Box>
                        }

                        <Box sx={{display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: '15px'}}>
                            {loadedData && (
                                <Switch
                                    checked={recurringBooking.active}
                                    onChange={(event) => setChecked(event.target.checked)}
                                    slotProps={{
                                        track: {
                                            children: (
                                                <React.Fragment>
                                                    <Typography component="span" level="inherit" sx={{ml: '10px'}}>
                                                        On
                                                    </Typography>
                                                    <Typography component="span" level="inherit" sx={{mr: '8px'}}>
                                                        Off
                                                    </Typography>
                                                </React.Fragment>
                                            ),
                                        },
                                    }}
                                    sx={{
                                        marginLeft: 'auto',
                                        marginRight: '10px',
                                        '--Switch-thumbSize': '27px',
                                        '--Switch-trackWidth': '64px',
                                        '--Switch-trackHeight': '31px',
                                    }}
                                />
                            )}
                            {loadedData && recurringBookingId !== -1 && (
                                <Button type="submit">Save Recurring
                                    Booking</Button>
                            )}
                            {loadedData && recurringBookingId === -1 && (
                                <Button type="submit">Create New Recurring
                                    Booking</Button>
                            )}
                        </Box>
                    </form>
                </ModalDialog>
            </Modal>
        </>
    )
}