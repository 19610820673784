import * as React from 'react';
import IconButton from '@mui/joy/IconButton';
import Table from '@mui/joy/Table';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {useEffect, useState} from "react";
import {GetAllRecurringBookings} from "../../axios/Bookings-Axios";
import Button from "@mui/joy/Button";
import Box from "@mui/joy/Box";
import {Close, Done, KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import {CircularProgress, Select} from "@mui/joy";
import Input from "@mui/joy/Input";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import {useSearchParams} from "react-router-dom";
import BookingsModal from "../modals/BookingsModal";
import DebounceInput from "../../domain/DebounceInput";
import RecurringBookingsModal from "../modals/RecurringBookingsModal";

interface Row {
    id: number;
    frequencyInterval: string;
    service: Service;
    service_price: ServicePrice;
    interval: string;
    frequency: string;
    created_at: string;
    active: boolean;
    expanded_row: RowExpanded
}

interface RowExpanded {
    full_name: string,
    street_address: string,
    postcode: string,
    phone: string,
    notes: string,
    house_type: string,
    id: string,
    email: string,
}

function formatDate(old_date) {
    let date = new Date(old_date);

    if (!isNaN(date.getTime())) {
        return date.toLocaleString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        });
    } else {
        return 'No Valid Date';
    }
}

function Row(props: { row: Row; initialOpen?: boolean, onRowClick: (rowId: number) => void }) {
    const {row} = props;
    const [open, setOpen] = React.useState(props.initialOpen || false);

    const handleClick = () => {
        props.onRowClick(Number(row.id));
    };

    return (
        <React.Fragment>
            <tr style={{width: '100%'}}>
                <td>
                    <IconButton
                        aria-label="expand row"
                        variant="plain"
                        color="neutral"
                        size="sm"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </td>
                <td>{formatDate(row.created_at)}</td>
                <td>{row.service.name}</td>
                <td>{row.expanded_row.full_name}</td>
                <td>£{row.service_price.price}</td>
                <td>Every {row.frequencyInterval}s</td>
                <td>{!row.active ? <Close color="action"/> : <Done color="success"/>}</td>
                <td>
                    <Button size="sm" color="primary" onClick={handleClick}>
                        Edit Recurring Booking
                    </Button>
                </td>

            </tr>
            <tr>
                <td style={{height: 0, padding: 0, width: '100%'}} colSpan={8}>
                    {open && (
                        <Sheet
                            variant="soft"
                            sx={{p: 1, pl: 6, boxShadow: 'inset 0 3px 6px 0 rgba(0 0 0 / 0.08)'}}
                        >
                            <Typography level="body-lg" component="div">
                                Customer Information
                            </Typography>
                            <Table
                                borderAxis="bothBetween"
                                aria-label="purchases"
                                sx={{
                                    '& > thead > tr > th:nth-child(n + 3), & > tbody > tr > td:nth-child(n + 3)':
                                        {textAlign: 'right'},
                                    '--TableCell-paddingX': '0.5rem',
                                }}
                            >
                                <thead>
                                <tr>
                                    <th>Full Name</th>
                                    <th>Number</th>
                                    <th>Email</th>
                                    <th>Street Address</th>
                                    <th>Postcode</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr key={row.id}>
                                    <td>{row.expanded_row.full_name}</td>
                                    <td>{row.expanded_row.phone}</td>
                                    <td>{row.expanded_row.email}</td>
                                    <td>{row.expanded_row.street_address}</td>
                                    <td>{row.expanded_row.postcode}</td>
                                </tr>
                                </tbody>
                            </Table>
                        </Sheet>
                    )}
                </td>
            </tr>
            <tr>
                <td style={{height: 0, padding: 0, width: '100%'}} colSpan={8}>
                    {open && (
                        <Sheet
                            variant="soft"
                            sx={{p: 1, pl: 6, boxShadow: 'inset 0 3px 6px 0 rgba(0 0 0 / 0.08)'}}
                        >
                            <Table
                                borderAxis="bothBetween"
                                aria-label="purchases"
                                sx={{
                                    '& > thead > tr > th:nth-child(n + 3), & > tbody > tr > td:nth-child(n + 3)':
                                        {textAlign: 'right'},
                                    '--TableCell-paddingX': '0.5rem',
                                }}
                            >
                                <thead>
                                <tr>
                                    <th>Notes</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td style={{whiteSpace: 'pre'}}>{row.expanded_row.notes !== '' ? row.expanded_row.notes : 'No notes'}</td>
                                </tr>
                                </tbody>
                            </Table>
                        </Sheet>
                    )}
                </td>
            </tr>

        </React.Fragment>
    );
}

const mapRawDataToRow = (data: any[]): Row[] => {
    return data.map(item => {
        return {
            id: item.id,
            frequencyInterval: item.interval + ' ' + item.frequency,
            service: item.service,
            service_price: item.service_price,
            interval: item.interval,
            frequency: item.frequency,
            created_at: item.created_at,
            active: item.active,
            expanded_row: {
                full_name: item.customer.full_name,
                street_address: item.customer.street_address,
                postcode: item.customer.postcode,
                phone: item.customer.phone,
                notes: item.customer.notes,
                house_type: item.customer.house_type || '',
                id: item.customer.id.toString(),
                email: item.customer.email,
            },
        };
    });
};

function labelDisplayedRows({
                                from,
                                to,
                                count,
                            }: {
    from: number;
    to: number;
    count: number;
}) {
    return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
}

export default function RecurringBookingsTable() {
    const [loadedData, setLoadingData] = React.useState(false);
    const [rows, setRows] = React.useState <Row[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [pageCount, setPageCount] = React.useState(0);
    const [search, setSearch] = React.useState('');
    const [urlSearchParam, setUrlSearchParam] = useSearchParams();
    const [openRecurringBookingId, setOpenRecurringBookingId] = React.useState(0);
    const [openRecurringBookingModal, setOpenRecurringBookingModal] = React.useState(false);


    let handleCallback = () => {
        setOpenRecurringBookingModal(false)
    };

    const handleOpenRecurringBookingModal = (rowId) => {
        setOpenRecurringBookingModal(true)
        setOpenRecurringBookingId(rowId)
    };

    const handleNewOpenRecurringBookingModal = () => {
        handleOpenRecurringBookingModal(-1)
    };

    const getLabelDisplayedRowsTo = () => {
        if (pageCount === -1) {
            return (page + 1) * rowsPerPage;
        }
        return rowsPerPage === -1
            ? pageCount
            : Math.min(pageCount, (page + 1) * rowsPerPage);
    };

    const handleChangePage = (newPage: number) => {
        setPage(newPage);
    };

    useEffect(() => {
        if (!loadedData) {
            return
        }
        setLoadingData(false)
        const fetchData = async () => {
            let location = localStorage.getItem('location')?.toLowerCase().replace(' ', '_')
            let bookings = await GetAllRecurringBookings([location], page + 1, search);
            setRows(mapRawDataToRow(bookings['results']))
            setPageCount(bookings['count'])

            setLoadingData(true)
        };
        fetchData()
    }, [page, search]);

    useEffect(() => {
        setLoadingData(false)

        const fetchData = async () => {
            let location = localStorage.getItem('location')?.toLowerCase().replace(' ', '_')
            let airTableBookings = await GetAllRecurringBookings([location], page + 1, search);
            setRows(mapRawDataToRow(airTableBookings['results']))
            setPageCount(airTableBookings['count'])

            setLoadingData(true)
        };
        fetchData()
    }, []);

    const handleDebounce = (value: string) => {
        setSearch(value);
    };


    return (
        <>
            <Box style={{display: 'flex', justifyContent: 'space-between'}}>
                <DebounceInput sx={{width: '15%'}} startDecorator={<SearchRoundedIcon/>}
                               placeholder="Search by any field..."
                               debounceTimeout={1000}
                               defaultValue={urlSearchParam.get("lead-id") as string}
                               handleDebounce={handleDebounce}
                />

                <Button onClick={() => {
                    handleNewOpenRecurringBookingModal()
                }} sx={{marginLeft: '10px'}}>Create New Recurring
                    Booking</Button>
            </Box>
            <Sheet>

                <Table
                    hoverRow
                    aria-label="collapsible table"
                    sx={{
                        height: '100%',
                        width: '100%',
                        '& > thead > tr > th:nth-child(n + 3), & > tbody > tr > td:nth-child(n + 3)':
                            {textAlign: 'right'},
                        '& > tbody > tr:nth-child(odd) > td, & > tbody > tr:nth-child(odd) > th[scope="row"]':
                            {
                                borderBottom: 0,
                            },
                    }}
                >
                    <thead>
                    <tr style={{width: '100%'}}>
                        <th style={{width: 40}} aria-label="empty"/>
                        <th style={{width: '15%'}}>Created At</th>
                        <th style={{width: '15%'}}>Service</th>
                        <th style={{width: '10%'}}>Customer Name</th>
                        <th style={{width: '10%'}}>Service Price</th>
                        <th style={{width: '10%'}}>How Often</th>
                        <th style={{width: '200px'}}>Active</th>
                        <th aria-label="last"></th>
                    </tr>
                    </thead>
                    {loadedData ?
                        <>
                            <tbody>
                            {rows.map((row, index) => (
                                <Row key={row.id} row={row} initialOpen={search.length !== 0}
                                     onRowClick={handleOpenRecurringBookingModal}/>
                            ))}
                            </tbody>
                            <tfoot>
                            <tr>
                                <td colSpan={8}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 2,
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        <Typography textAlign="center" sx={{minWidth: 80}}>
                                            {labelDisplayedRows({
                                                from: pageCount === 0 ? 0 : page * rowsPerPage + 1,
                                                to: getLabelDisplayedRowsTo(),
                                                count: pageCount === -1 ? -1 : pageCount,
                                            })}
                                        </Typography>
                                        <Box sx={{display: 'flex', gap: 1}}>
                                            <IconButton
                                                size="sm"
                                                color="neutral"
                                                variant="outlined"
                                                disabled={page === 0}
                                                onClick={() => handleChangePage(page - 1)}
                                                sx={{bgcolor: 'background.surface'}}
                                            >
                                                <KeyboardArrowLeft/>
                                            </IconButton>
                                            <IconButton
                                                size="sm"
                                                color="neutral"
                                                variant="outlined"
                                                disabled={
                                                    pageCount !== -1
                                                        ? page >= Math.ceil(pageCount / rowsPerPage) - 1
                                                        : false
                                                }
                                                onClick={() => handleChangePage(page + 1)}
                                                sx={{bgcolor: 'background.surface'}}
                                            >
                                                <KeyboardArrowRight/>
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </td>
                            </tr>
                            </tfoot>
                        </>
                        :
                        <tbody>
                        <tr style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            position: 'absolute',
                            marginTop: '50px',
                        }}>
                            <CircularProgress
                                size="lg"
                            />
                        </tr>
                        </tbody>
                    }
                </Table>

            </Sheet>
            <React.Fragment>
                <RecurringBookingsModal key={'recurring-bookings-modal-' + openRecurringBookingModal}
                                        openState={openRecurringBookingModal}
                                        recurringBookingId={openRecurringBookingId} parentCallback={handleCallback}/>
            </React.Fragment>
        </>


    );
}