import * as React from 'react';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';

type ChatBubbleProps = MessageProps & {
    variant: 'sent' | 'received';
};

function formatDate(old_date) {
    let date = new Date(old_date);

    if (!isNaN(date.getTime())) {
        const now: Date = new Date();
        const diffInSeconds: number = Math.floor((now.getTime() - date.getTime()) / 1000);
        let timeAgo: string;
        if (diffInSeconds < 60) {
            timeAgo = `${diffInSeconds} second${diffInSeconds !== 1 ? 's' : ''} ago`;
        } else if (diffInSeconds < 3600) { // less than an hour
            const diffInMinutes = Math.floor(diffInSeconds / 60);
            timeAgo = `${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''} ago`;
        } else if (diffInSeconds < 86400) { // less than a day
            const diffInHours = Math.floor(diffInSeconds / 3600);
            timeAgo = `${diffInHours} hour${diffInHours !== 1 ? 's' : ''} ago`;
        } else if (diffInSeconds < 604800) { // less than a week
            const diffInDays = Math.floor(diffInSeconds / 86400);
            timeAgo = `${diffInDays} day${diffInDays !== 1 ? 's' : ''} ago`;
        } else if (diffInSeconds < 2628000) { // less than a month
            const diffInWeeks = Math.floor(diffInSeconds / 604800);
            timeAgo = `${diffInWeeks} week${diffInWeeks !== 1 ? 's' : ''} ago`;
        } else if (diffInSeconds < 31536000) { // less than a year
            const diffInMonths = Math.floor(diffInSeconds / 2628000);
            timeAgo = `${diffInMonths} month${diffInMonths !== 1 ? 's' : ''} ago`;
        } else { // more than a year
            const diffInYears = Math.floor(diffInSeconds / 31536000);
            timeAgo = `${diffInYears} year${diffInYears !== 1 ? 's' : ''} ago`;
        }

        return `${date.toLocaleString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        })} (${timeAgo})`;
    } else {
        return 'No Valid Date';
    }
}


export default function ChatBubble(props: ChatBubbleProps) {
    const {content, variant, timestamp, attachment = undefined, sender} = props;
    const isSent = variant === 'sent';
    const [isHovered, setIsHovered] = React.useState<boolean>(false);
    return (
        <Box sx={{maxWidth: '60%', minWidth: 'auto'}}>
            <Stack
                direction="row"
                spacing={2}
                sx={{justifyContent: 'space-between', mb: 0.25}}
            >
                <Typography level="body-xs">
                    {sender.username}
                </Typography>
                <Typography level="body-xs">{formatDate(timestamp)}</Typography>
            </Stack>
            {attachment ? (
                <Sheet
                    variant="outlined"
                    sx={[
                        {
                            px: 1.75,
                            py: 1.25,
                            borderRadius: 'lg',
                        },
                        isSent ? {borderTopRightRadius: 0} : {borderTopRightRadius: 'lg'},
                        isSent ? {borderTopLeftRadius: 'lg'} : {borderTopLeftRadius: 0},
                    ]}
                >
                    <Stack direction="row" spacing={1.5} sx={{alignItems: 'center'}}>
                        <Avatar color="primary" size="lg">
                            <InsertDriveFileRoundedIcon/>
                        </Avatar>
                        <div>
                            <Typography sx={{fontSize: 'sm'}}>{attachment.fileName}</Typography>
                            <Typography level="body-sm">{attachment.size}</Typography>
                        </div>
                    </Stack>
                </Sheet>
            ) : (
                <Box
                    sx={{position: 'relative'}}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    <Sheet
                        color={isSent ? 'primary' : 'neutral'}
                        variant={isSent ? 'solid' : 'soft'}
                        sx={[
                            {
                                p: 1.25,
                                borderRadius: 'lg',
                            },
                            isSent
                                ? {
                                    borderTopRightRadius: 0,
                                }
                                : {
                                    borderTopRightRadius: 'lg',
                                },
                            isSent
                                ? {
                                    borderTopLeftRadius: 'lg',
                                }
                                : {
                                    borderTopLeftRadius: 0,
                                },
                            isSent
                                ? {
                                    backgroundColor: 'var(--joy-palette-primary-solidBg)',
                                }
                                : {
                                    backgroundColor: 'background.body',
                                },
                        ]}
                    >
                        <Typography
                            level="body-sm"
                            sx={[
                                isSent
                                    ? {
                                        color: 'var(--joy-palette-common-white)',
                                        maxWidth: '100%',
                                        wordWrap: 'break-word',
                                    }
                                    : {
                                        color: 'var(--joy-palette-text-primary)',
                                        maxWidth: '100%',
                                        wordWrap: 'break-word',
                                    },
                            ]}
                        >
                            {content}
                        </Typography>
                    </Sheet>

                </Box>
            )}
        </Box>
    );
}