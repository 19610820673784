import * as React from 'react';
import {Breadcrumbs, Chip, CircularProgress, CssBaseline, CssVarsProvider, Link, Select} from "@mui/joy";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import SideNav from "../dashboard-components/SideNav";
import Header from "../dashboard-components/Header";
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import {useEffect, useState} from "react";
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import {TrendingDown, TrendingFlat, TrendingUp} from "@mui/icons-material";
import {OverridableStringUnion} from "@mui/types";
import {ColorPaletteProp} from "@mui/joy/styles/types";
import {ChipPropsColorOverrides} from "@mui/joy/Chip/ChipProps";
import {GetFranchiseStatistics} from "../../axios/Bookings-Axios";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {addDays} from 'date-fns';
import {DateRangePicker} from 'react-date-range';
import Sheet from "@mui/joy/Sheet";


interface LoadedStatistic {
    bookings: number;
    leads: number;
    conversionRate: number;
    convertedLeads: number;
    leadsPriceTotal: number;
    bookingsPriceTotal: number;
    bookingsCompleted: number;
    cashAmount: number;
    cardAmount: number;
    invoicedAmount: number;
    invoicesReceived: number;
    invoicesMissing: number;
    convertedByGoogle: number;
    convertedByEmail: number;
    convertedManually: number;
    convertedBySMS: number;
    convertedWindowClean: number;
    convertedGutterClean: number;
    recurringBookingsPriceTotal: number;
    newMessages: number;
    newWindowCleanRecurringBooking: number;
    newGutterCleanRecurringBooking: number;
    windowLeads: number;
    gutterLeads: number;


}

export default function Home() {
    const [statistics, setStatistics] = useState<LoadedStatistic>({
        cardAmount: 0, cashAmount: 0,
        convertedByEmail: 0, convertedByGoogle: 0, convertedManually: 0,
        invoicedAmount: 0, invoicesMissing: 0, invoicesReceived: 0,
        bookingsCompleted: 0, bookingsPriceTotal: 0, leadsPriceTotal: 0,
        bookings: 0,
        leads: 0,
        newMessages: 0,
        convertedLeads: 0,
        conversionRate: 0,
        recurringBookingsPriceTotal: 0,
        newGutterCleanRecurringBooking: 0,
        newWindowCleanRecurringBooking: 0,
        convertedBySMS: 0,
        convertedGutterClean: 0,
        convertedWindowClean: 0,
        gutterLeads: 0,
        windowLeads: 0,

    });
    const [loadedData, setLoadingData] = React.useState(false);

    const [dateRange, setDateRange] = useState([
        {
            startDate: addDays(new Date(), -15),
            endDate: new Date(),
            key: 'selection'
        }
    ]);


    useEffect(() => {
        setLoadingData(false)

        const fetchData = async () => {
            let locations = localStorage.getItem('location')?.toLowerCase().replace(' ', '_')
            const statisticsResponse = await GetFranchiseStatistics([locations], dateRange[0].startDate.toLocaleDateString('en-GB'), dateRange[0].endDate.toLocaleDateString('en-GB'));

            setStatistics(statisticsResponse);

            setLoadingData(true)

        };
        fetchData()
    }, [dateRange]);


    return (
        <CssVarsProvider disableTransitionOnChange>
            <CssBaseline/>
            <Box sx={{display: 'flex', minHeight: '100dvh'}}>
                <Header/>
                <SideNav/>


                <Box
                    component="main"
                    className="MainContent"
                    sx={{
                        px: {xs: 2, md: 6},
                        pt: {
                            xs: 'calc(12px + var(--Header-height))',
                            sm: 'calc(12px + var(--Header-height))',
                            md: 3,
                        },
                        pb: {xs: 2, sm: 2, md: 3},
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 0,
                        height: '100dvh',
                        gap: 1,
                    }}
                >
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Breadcrumbs
                            size="sm"
                            aria-label="breadcrumbs"
                            separator={<ChevronRightRoundedIcon/>}
                            sx={{pl: 0}}
                        >
                            <Link
                                underline="none"
                                color="neutral"
                                href="#some-link"
                                aria-label="Home"
                            >
                                <HomeRoundedIcon/>
                            </Link>
                            <Link
                                underline="hover"
                                color="neutral"
                                href="#some-link"
                                fontSize={12}
                                fontWeight={500}
                            >
                                Dashboard
                            </Link>
                            <Typography color="primary" fontWeight={500} fontSize={12}>
                                Home
                            </Typography>
                        </Breadcrumbs>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            mb: 1,
                            gap: 1,
                            flexDirection: {xs: 'column', sm: 'row'},
                            flexWrap: 'wrap',
                            height: '100%'
                        }}
                    >
                        <Box sx={{width: '100%'}}>
                            <Typography level="h2" component="h1">
                                Home
                            </Typography>


                            <Tabs orientation="horizontal"
                                  size="lg" sx={{width: '100%', height: '90%'}} defaultValue={0}>
                                <TabList sx={{width: '100%', height: '50px'}} disableUnderline>
                                    <Tab variant="solid"
                                         color="primary"
                                         indicatorInset>Summary</Tab>
                                    <Tab disabled variant="solid"
                                         color="primary"
                                         indicatorInset>Lead Analysis</Tab>
                                    <Tab disabled variant="solid"
                                         color="primary"
                                         indicatorInset>Efficacy</Tab>
                                    <Tab disabled variant="solid"
                                         color="primary"
                                         indicatorInset>Due Or Missed</Tab>
                                    <Tab disabled variant="solid"
                                         color="primary"
                                         indicatorInset>Leads By Source</Tab>
                                </TabList>

                                <TabPanel value={0}>

                                    <Box sx={{
                                        display: 'flex',
                                        height: '600px'
                                    }}>
                                        <Sheet variant={"outlined"} sx={{background: 'white'}}>
                                            <DateRangePicker
                                                onChange={item => setDateRange([item.selection])}
                                                showSelectionPreview={true}
                                                moveRangeOnFirstSelection={false}
                                                months={2}
                                                ranges={dateRange}
                                                direction="vertical"
                                            />
                                        </Sheet>
                                        <Sheet sx={{
                                            width: '100%',
                                            height: '100%',
                                            background: 'white',
                                            padding: '25px'
                                        }}
                                               variant={"outlined"}>
                                            {!loadedData ? (
                                                <Box sx={{
                                                    display: 'flex',
                                                    gap: 2,
                                                    alignItems: 'center',
                                                    flexWrap: 'wrap',
                                                    width: '100%',
                                                    height: '100%',
                                                    justifyContent: 'center'
                                                }}>
                                                    <CircularProgress
                                                        size="lg"
                                                    />
                                                </Box>
                                            ) : (
                                                <>

                                                    <Box sx={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        justifyContent: 'space-between'
                                                    }}>
                                                        <Sheet variant={"outlined"} sx={{padding: 2, width: '24%'}}>
                                                            <Typography fontSize="sm" color="neutral"
                                                                        fontWeight={500}>Bookings</Typography>
                                                            <Typography>{statistics.bookings}</Typography>
                                                        </Sheet>
                                                        <Sheet variant={"outlined"} sx={{padding: 2, width: '24%'}}>
                                                            <Typography fontSize="sm" color="neutral"
                                                                        fontWeight={500}>Leads</Typography>
                                                            <Typography>{statistics.leads}</Typography>
                                                        </Sheet>
                                                        <Sheet variant={"outlined"} sx={{padding: 2, width: '24%'}}>
                                                            <Typography fontSize="sm" color="neutral"
                                                                        fontWeight={500}>Bookings
                                                                Total
                                                                Value</Typography>
                                                            <Typography>£{statistics.bookingsPriceTotal}</Typography>
                                                        </Sheet>
                                                        <Sheet variant={"outlined"} sx={{padding: 2, width: '24%'}}>
                                                            <Typography fontSize="sm" color="neutral"
                                                                        fontWeight={500}>Leads Total
                                                                Value</Typography>
                                                            <Typography>£{statistics.leadsPriceTotal}</Typography>
                                                        </Sheet>
                                                    </Box>

                                                    <Box sx={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        justifyContent: 'space-between',
                                                        marginTop: 1
                                                    }}>
                                                        <Sheet variant={"outlined"} sx={{padding: 2, width: '24%'}}>
                                                            <Typography fontSize="sm" color="neutral"
                                                                        fontWeight={500}>Conversion
                                                                Rate</Typography>
                                                            <Typography>{statistics.conversionRate}%</Typography>
                                                        </Sheet>
                                                        <Sheet variant={"outlined"} sx={{padding: 2, width: '24%'}}>
                                                            <Typography fontSize="sm" color="neutral"
                                                                        fontWeight={500}>Invoiced Amount</Typography>
                                                            <Typography>£{statistics.invoicedAmount}</Typography>
                                                        </Sheet>
                                                        <Sheet variant={"outlined"} sx={{padding: 2, width: '24%'}}>
                                                            <Typography fontSize="sm" color="neutral"
                                                                        fontWeight={500}>Invoices Received</Typography>
                                                            <Typography>£{statistics.invoicesReceived}</Typography>
                                                        </Sheet>
                                                        <Sheet variant={"outlined"} sx={{padding: 2, width: '24%'}}>
                                                            <Typography fontSize="sm" color="neutral"
                                                                        fontWeight={500}>Missing Invoices</Typography>
                                                            <Typography>£{statistics.invoicesMissing}</Typography>
                                                        </Sheet>
                                                    </Box>

                                                    <Box sx={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        justifyContent: 'space-between',
                                                        marginTop: 1
                                                    }}>
                                                        <Sheet variant={"outlined"} sx={{padding: 2, width: '24%'}}>
                                                            <Typography fontSize="sm" color="neutral"
                                                                        fontWeight={500}>Cash Amount</Typography>
                                                            <Typography>£{statistics.cashAmount}</Typography>
                                                        </Sheet>
                                                        <Sheet variant={"outlined"} sx={{padding: 2, width: '24%'}}>
                                                            <Typography fontSize="sm" color="neutral"
                                                                        fontWeight={500}>Card Amount</Typography>
                                                            <Typography>£{statistics.cardAmount}</Typography>
                                                        </Sheet>
                                                        <Sheet variant={"outlined"} sx={{padding: 2, width: '24%'}}>

                                                            <Typography fontSize="sm" color="neutral"
                                                                        fontWeight={500}>Recurring Booking Total
                                                                Value</Typography>
                                                            <Typography>£{statistics.recurringBookingsPriceTotal}</Typography>
                                                        </Sheet>
                                                        <Sheet variant={"outlined"} sx={{padding: 2, width: '24%'}}>
                                                            <Typography fontSize="sm" color="neutral"
                                                                        fontWeight={500}>New Messages From
                                                                Customers</Typography>
                                                            <Typography>{statistics.newMessages}</Typography>
                                                        </Sheet>
                                                    </Box>

                                                    <Box sx={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        justifyContent: 'space-between',
                                                        marginTop: 1
                                                    }}>
                                                        <Sheet variant={"outlined"} sx={{padding: 2, width: '24%'}}>
                                                            <Typography fontSize="sm" color="neutral"
                                                                        fontWeight={500}>New Window Clean Recurring
                                                                Bookings</Typography>
                                                            <Typography>{statistics.newWindowCleanRecurringBooking}</Typography>
                                                        </Sheet>
                                                        <Sheet variant={"outlined"} sx={{padding: 2, width: '24%'}}>
                                                            <Typography fontSize="sm" color="neutral"
                                                                        fontWeight={500}>New Gutter Clean Recurring
                                                                Bookings</Typography>
                                                            <Typography>{statistics.newGutterCleanRecurringBooking}</Typography>
                                                        </Sheet>
                                                        <Sheet variant={"outlined"} sx={{padding: 2, width: '24%'}}>


                                                        </Sheet>
                                                        <Sheet variant={"outlined"} sx={{padding: 2, width: '24%'}}>
                                                            <Typography fontSize="sm" color="neutral"
                                                                        fontWeight={500}>Total Converted</Typography>
                                                            <Typography>{statistics.convertedLeads}</Typography>
                                                        </Sheet>
                                                    </Box>

                                                    <Box sx={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        justifyContent: 'space-between',
                                                        marginTop: 1
                                                    }}>
                                                        <Sheet variant={"outlined"} sx={{padding: 2, width: '24%'}}>
                                                            <Typography fontSize="sm" color="neutral"
                                                                        fontWeight={500}>Converted Through Google
                                                                SMS</Typography>
                                                            <Typography>{statistics.convertedBySMS}</Typography>
                                                        </Sheet>
                                                        <Sheet variant={"outlined"} sx={{padding: 2, width: '24%'}}>
                                                            <Typography fontSize="sm" color="neutral"
                                                                        fontWeight={500}>Converted Manually</Typography>
                                                            <Typography>{statistics.convertedManually}</Typography>
                                                        </Sheet>
                                                        <Sheet variant={"outlined"} sx={{padding: 2, width: '24%'}}>
                                                            <Typography fontSize="sm" color="neutral"
                                                                        fontWeight={500}>Converted Through Google
                                                                Ads</Typography>
                                                            <Typography>{statistics.convertedByGoogle}</Typography>
                                                        </Sheet>
                                                        <Sheet variant={"outlined"} sx={{padding: 2, width: '24%'}}>
                                                            <Typography fontSize="sm" color="neutral"
                                                                        fontWeight={500}>Converted Through Email
                                                                Funnel</Typography>
                                                            <Typography>{statistics.convertedByEmail}</Typography>
                                                        </Sheet>
                                                    </Box>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        justifyContent: 'space-between',
                                                        marginTop: 1
                                                    }}>
                                                        <Sheet variant={"outlined"} sx={{padding: 2, width: '24%'}}>
                                                            <Typography fontSize="sm" color="neutral"
                                                                        fontWeight={500}>Converted Automatically Window
                                                                Clean</Typography>
                                                            <Typography>{statistics.convertedWindowClean}</Typography>
                                                        </Sheet>
                                                        <Sheet variant={"outlined"} sx={{padding: 2, width: '24%'}}>
                                                            <Typography fontSize="sm" color="neutral"
                                                                        fontWeight={500}>Converted Automatically Gutter
                                                                Clean</Typography>
                                                            <Typography>{statistics.convertedGutterClean}</Typography>
                                                        </Sheet>
                                                        <Sheet variant={"outlined"} sx={{padding: 2, width: '24%'}}>
                                                            <Typography fontSize="sm" color="neutral"
                                                                        fontWeight={500}>Window Clean Form
                                                                Leads</Typography>
                                                            <Typography>{statistics.gutterLeads}</Typography>
                                                        </Sheet>
                                                        <Sheet variant={"outlined"} sx={{padding: 2, width: '24%'}}>
                                                            <Typography fontSize="sm" color="neutral"
                                                                        fontWeight={500}>Gutter Clean Form
                                                                Leads</Typography>
                                                            <Typography>{statistics.windowLeads}</Typography>
                                                        </Sheet>
                                                    </Box>

                                                </>

                                            )}
                                        </Sheet>


                                    </Box>

                                </TabPanel>
                                <TabPanel value={1}>
                                    <b>Second</b> tab panel
                                </TabPanel>
                                <TabPanel value={2}>
                                    <b>Third</b> tab panel
                                </TabPanel>
                            </Tabs>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </CssVarsProvider>
    )

}

function NumberStatisticCard({title, value, incrementValue, incrementPercentage, from, currency}) {
    let increment = 'increase';
    let startDecorator = <TrendingUp/>;
    let color: OverridableStringUnion<ColorPaletteProp, ChipPropsColorOverrides> = 'success';
    const formattedValue = currency ? `£${Math.round(value)}` : value;
    const formattedIncrementedValue = currency ? `£${Math.round(incrementValue)}` : incrementValue;

    if (incrementPercentage < 0) {
        startDecorator = <TrendingDown/>
        increment = 'decrease'
        color = 'danger'
        incrementPercentage = Math.abs(incrementPercentage);

    }

    if (incrementPercentage === 0) {
        increment = 'no change'
        color = 'neutral'
        startDecorator = <TrendingFlat/>
    }


    return (
        <>
            <Card sx={{margin: 2, minWidth: '280px'}} color="primary" variant="outlined">
                <CardContent orientation="vertical">
                    <CardContent>
                        <Typography level="title-md">{title}</Typography>
                        <Typography level="title-lg">{formattedValue}</Typography>
                    </CardContent>
                    <Chip
                        startDecorator={startDecorator}
                        sx={{height: '35px'}}
                        color={color}
                        size="md"
                        variant="soft"
                    >{incrementPercentage}% {increment} from {from}</Chip>
                    <Chip
                        sx={{height: '35px'}}
                        color='neutral'
                        size="md"
                        variant="soft"
                    >{formattedIncrementedValue} {from}</Chip>
                </CardContent>
            </Card>
        </>
    )
}

function PercentageStatisticCard({title, firstValue, secondValue}) {
    const percentage = Math.round((firstValue / secondValue) * 100)

    return (
        <>
            <Card sx={{margin: 2, maxWidth: '280px', minWidth: '280px'}} variant="solid" color="primary" invertedColors>
                <CardContent orientation="horizontal">
                    <CircularProgress size="lg" determinate value={percentage}>
                        <Typography>{percentage}%</Typography>
                    </CircularProgress>
                    <CardContent>
                        <Typography level="body-md">{title}</Typography>
                        <Typography level="title-lg">{firstValue} / {secondValue}</Typography>
                    </CardContent>
                </CardContent>
            </Card>
        </>
    )
}