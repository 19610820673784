import * as React from 'react';
import IconButton from '@mui/joy/IconButton';
import Table from '@mui/joy/Table';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {useEffect, useState} from "react";
import {GetAllFranchises, GetUser} from "../../axios/Axios";
import {GetAllLeads} from "../../axios/Bookings-Axios";
import Button from "@mui/joy/Button";
import Box from "@mui/joy/Box";
import {Close, Done, KeyboardArrowLeft, KeyboardArrowRight, MenuBook, TuneRounded} from "@mui/icons-material";
import {Card, Chip, CircularProgress, Select} from "@mui/joy";
import Input from "@mui/joy/Input";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import {useSearchParams} from "react-router-dom";
import LeadsModal from "../modals/LeadsModal";
import DebounceInput from "../../domain/DebounceInput";
import Option from "@mui/joy/Option";

interface Franchise {
    location: string;
}

interface User {
    first_name: string;
    last_name: string;
    location_permissions: string[];
}

interface Row {
    id: string,
    booked_in: string,
    created_at: string,
    is_recurring: boolean,
    gutter_clean: boolean,
    conservatory_gutter_clean: boolean,
    window_clean: boolean,
    expanded_row: RowExpanded
}

interface Status {
    id: string,
    created_at: string,
    status: string,
    user: string,
}

interface RowExpanded {
    full_name: string,
    street_address: string,
    postcode: string,
    phone: string,
    notes: string,
    house_type: string,
    id: string,
    email: string,
    status: Status[],
}

function formatDate(old_date) {
    let date = new Date(old_date);

    // Check if the date is valid
    if (!isNaN(date.getTime())) {
        // Date is valid, format it
        return date.toLocaleString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        });
    } else {
        return 'No Valid Date';
    }
}

function Row(props: { row: Row; initialOpen?: boolean, onRowClick: (rowId: number) => void }) {
    const {row} = props;
    const [open, setOpen] = React.useState(props.initialOpen || false);


    const handleClick = () => {
        props.onRowClick(Number(row.id));
    };


    return (
        <React.Fragment>
            <tr style={{width: '100%'}}>
                <td>
                    <IconButton
                        aria-label="expand row"
                        variant="plain"
                        color="neutral"
                        size="sm"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </td>
                <th>{formatDate(row.created_at)}</th>
                <td>{row.expanded_row.full_name}</td>
                <td>{!row.window_clean ? <Close color="action"/> : <Done color="success"/>}</td>
                <td>{!row.gutter_clean ? <Close color="action"/> : <Done color="success"/>}</td>
                <td>{!row.conservatory_gutter_clean ? <Close color="action"/> : <Done color="success"/>}</td>
                <td>
                    <Button size="sm" color="primary" onClick={handleClick}>
                        Edit/Convert To booking
                    </Button>
                </td>

            </tr>
            <tr>
                <td style={{height: 0, padding: 0, width: '100%'}} colSpan={7}>
                    {open && (
                        <Sheet
                            variant="soft"
                            sx={{p: 1, pl: 6, boxShadow: 'inset 0 3px 6px 0 rgba(0 0 0 / 0.08)'}}
                        >
                            <Typography level="body-lg" component="div">
                                Customer Information
                            </Typography>
                            <Table
                                borderAxis="bothBetween"
                                size="sm"
                                aria-label="purchases"
                                sx={{
                                    '& > thead > tr > th:nth-child(n + 3), & > tbody > tr > td:nth-child(n + 3)':
                                        {textAlign: 'right'},
                                    '--TableCell-paddingX': '0.5rem',
                                }}
                            >
                                <thead>
                                <tr>
                                    <th>Full Name</th>
                                    <th>Number</th>
                                    <th>Email</th>
                                    <th>Street Address</th>
                                    <th>Postcode</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr key={row.id}>
                                    <td>{row.expanded_row.full_name}</td>
                                    <td>{row.expanded_row.phone}</td>
                                    <td>{row.expanded_row.email}</td>
                                    <td>{row.expanded_row.street_address}</td>
                                    <td>{row.expanded_row.postcode}</td>
                                </tr>
                                </tbody>
                            </Table>
                        </Sheet>
                    )}
                </td>
            </tr>
            <tr>
                <td style={{height: 0, padding: 0, width: '100%'}} colSpan={7}>
                    {open && (
                        <Sheet
                            variant="soft"
                            sx={{p: 1, pl: 6, boxShadow: 'inset 0 3px 6px 0 rgba(0 0 0 / 0.08)'}}
                        >
                            <Table
                                borderAxis="bothBetween"
                                size="sm"
                                aria-label="purchases"
                                sx={{
                                    '& > thead > tr > th:nth-child(n + 3), & > tbody > tr > td:nth-child(n + 3)':
                                        {textAlign: 'right'},
                                    '--TableCell-paddingX': '0.5rem',
                                }}
                            >
                                <thead>
                                <tr>
                                    <th>Notes</th>
                                    <th>Lead Status History</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td style={{whiteSpace: 'pre'}}>{row.expanded_row.notes !== '' ? row.expanded_row.notes : 'No notes'}</td>
                                    <td style={{whiteSpace: 'pre'}}>
                                        {row.expanded_row.status.map((statusItem) => (
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                padding: '5px',
                                                marginBottom: '5px'
                                            }}>
                                                <Typography level="body-xs">{statusItem.created_at}</Typography>
                                                <Typography level="body-xs">-</Typography>
                                                <Typography level="body-xs">{statusItem.status}</Typography>
                                                <Typography level="body-xs">-</Typography>
                                                <Typography level="body-xs">{statusItem.user}</Typography>
                                            </div>
                                        ))}
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Sheet>
                    )}
                </td>
            </tr>
        </React.Fragment>
    );
}

const mapRawDataToRow = (data: any[]): Row[] => {
    return data.map(item => {
        const serviceNames = item.services.map(service => service.name.toLowerCase()); // Extracting service names and converting to lowercase
        const gutterClean = serviceNames.includes('gutter clean');
        const conservatoryGutterClean = serviceNames.includes('conservatory gutter clean');
        const windowClean = serviceNames.includes('window clean');
        return {
            id: item.id.toString(),
            booked_in: item.booked_in,
            created_at: item.created_at,
            is_recurring: item.is_recurring,
            gutter_clean: gutterClean,
            conservatory_gutter_clean: conservatoryGutterClean,
            window_clean: windowClean,
            expanded_row: {
                full_name: item.customer.full_name,
                street_address: item.customer.street_address,
                postcode: item.customer.postcode,
                phone: item.customer.phone,
                notes: item.customer.notes,
                house_type: item.customer.house_type || '',
                id: item.customer.id.toString(),
                email: item.customer.email,
                status: item.status,
            },
        };
    });
};

function labelDisplayedRows({
                                from,
                                to,
                                count,
                            }: {
    from: number;
    to: number;
    count: number;
}) {
    return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
}

export default function LeadsTable() {
    const [loadedData, setLoadingData] = React.useState(false);
    const [franchises, setFranchises] = useState<Franchise[]>([]);
    const [user, setUser] = useState<User>({first_name: '', last_name: '', location_permissions: []});
    const [rows, setRows] = React.useState <Row[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [pageCount, setPageCount] = React.useState(0);
    const [search, setSearch] = React.useState('');
    const [filter, setFilter] = React.useState('ready-to-book');
    const [urlSearchParam, setUrlSearchParam] = useSearchParams();


    const [openLeadId, setOpenLeadId] = React.useState(0);
    const [openLeadModal, setOpenLeadModal] = React.useState(false);


    const getLabelDisplayedRowsTo = () => {
        if (pageCount === -1) {
            return (page + 1) * rowsPerPage;
        }
        return rowsPerPage === -1
            ? pageCount
            : Math.min(pageCount, (page + 1) * rowsPerPage);
    };

    const handleChangePage = (newPage: number) => {
        setPage(newPage);
    };

    useEffect(() => {
        if (!loadedData) {
            return
        }
        setLoadingData(false)
        const fetchData = async () => {
            let location = localStorage.getItem('location')?.toLowerCase().replace(' ', '_')
            let leadsResponse = await GetAllLeads([location], page + 1, search, filter);
            setRows(mapRawDataToRow(leadsResponse['results']))
            setPageCount(leadsResponse['count'])

            setLoadingData(true)
        };
        fetchData()
    }, [page, search, filter]);

    useEffect(() => {
        setLoadingData(false)

        const fetchData = async () => {
            const userResponse = await GetUser();
            setUser(userResponse.data);
            let location = localStorage.getItem('location')?.toLowerCase().replace(' ', '_')
            let leadsResponse = await GetAllLeads([location], page + 1, search, filter);
            setRows(mapRawDataToRow(leadsResponse['results']))
            setPageCount(leadsResponse['count'])
            const franchisesResponse = await GetAllFranchises();
            setFranchises(franchisesResponse.data);

            setLoadingData(true)
        };
        fetchData()
    }, []);


    const handleNewOpenQuoteModal = () => {
        handleOpenLeadModal(-1)
    }
    const handleOpenLeadModal = (rowId) => {
        setOpenLeadModal(false);
        setTimeout(() => {
            setOpenLeadModal(true);
            setOpenLeadId(rowId);
        }, 100);
    };

    let handleCallback = () => {
        setOpenLeadModal(false)
    };

    const handleDebounce = (value: string) => {
        setSearch(value);
    };

    const handleChangeFilter = (
        event: React.SyntheticEvent | null,
        newValue: string | null,
    ) => {
        if (newValue != null) setFilter(newValue)
    };

    return (
        <>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box sx={{display: 'flex'}}>
                    <DebounceInput sx={{width: '15%', minWidth: '250px'}} startDecorator={<SearchRoundedIcon/>}
                                   placeholder="Search by any field..."
                                   debounceTimeout={1000}
                                   defaultValue={urlSearchParam.get("lead-id") as string}
                                   handleDebounce={handleDebounce}
                    />
                    <Select
                        placeholder="Filter by..."
                        defaultValue='ready-to-book'
                        onChange={handleChangeFilter}
                        startDecorator={<TuneRounded/>}
                        renderValue={(selected) => (
                            <Box sx={{display: 'flex', gap: '0.25rem'}}>
                                <Chip variant="soft" color="primary">
                                    {selected?.label}
                                </Chip>
                            </Box>
                        )}
                        sx={{
                            minWidth: '15rem',
                            marginLeft: '15px',
                        }}
                        slotProps={{
                            listbox: {
                                sx: {
                                    width: '100%',
                                },
                            },
                        }}
                    >
                        <Option value="all">All Bookings</Option>
                        <Option value="ready-to-book">Ready To Book</Option>
                        <Option value="booked-in">Booked In</Option>
                        <Option value="opt-out">Opted Out</Option>
                        <Option value="call-back">Need To Call Back</Option>
                        <Option value="no-answer">No Answer</Option>
                    </Select>
                </Box>

                <Button onClick={() => {
                    handleNewOpenQuoteModal()
                }} sx={{marginLeft: '10px'}}>Create New
                    Lead</Button>
            </Box>
            <Sheet>

                <Table
                    aria-label="collapsible table"
                    sx={{
                        height: '100%',
                        width: '100%',
                        '& > thead > tr > th:nth-child(n + 3), & > tbody > tr > td:nth-child(n + 3)':
                            {textAlign: 'right'},
                        '& > tbody > tr:nth-child(odd) > td, & > tbody > tr:nth-child(odd) > th[scope="row"]':
                            {
                                borderBottom: 0,
                            },
                    }}
                >
                    <thead>
                    <tr style={{width: '100%'}}>
                        <th style={{width: 40}} aria-label="empty"/>
                        <th style={{width: '15%'}}>Date Created</th>
                        <th style={{width: '10%'}}>Customer Name</th>
                        <th style={{width: '150px'}}>Window Clean</th>
                        <th style={{width: '150px'}}>Gutter Clean</th>
                        <th style={{width: '200px'}}>Conservatory Gutter Clean</th>
                        <th aria-label="last"></th>
                    </tr>
                    </thead>
                    {loadedData ?
                        <>
                            <tbody>
                            {rows.map((row, index) => (
                                <Row key={row.id} row={row} initialOpen={search.length !== 0}
                                     onRowClick={handleOpenLeadModal}/>
                            ))}
                            </tbody>
                            <tfoot>
                            <tr>
                                <td colSpan={7}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 2,
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        <Typography textAlign="center" sx={{minWidth: 80}}>
                                            {labelDisplayedRows({
                                                from: pageCount === 0 ? 0 : page * rowsPerPage + 1,
                                                to: getLabelDisplayedRowsTo(),
                                                count: pageCount === -1 ? -1 : pageCount,
                                            })}
                                        </Typography>
                                        <Box sx={{display: 'flex', gap: 1}}>
                                            <IconButton
                                                size="sm"
                                                color="neutral"
                                                variant="outlined"
                                                disabled={page === 0}
                                                onClick={() => handleChangePage(page - 1)}
                                                sx={{bgcolor: 'background.surface'}}
                                            >
                                                <KeyboardArrowLeft/>
                                            </IconButton>
                                            <IconButton
                                                size="sm"
                                                color="neutral"
                                                variant="outlined"
                                                disabled={
                                                    pageCount !== -1
                                                        ? page >= Math.ceil(pageCount / rowsPerPage) - 1
                                                        : false
                                                }
                                                onClick={() => handleChangePage(page + 1)}
                                                sx={{bgcolor: 'background.surface'}}
                                            >
                                                <KeyboardArrowRight/>
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </td>
                            </tr>
                            </tfoot>
                        </>
                        :
                        <tbody>
                        <tr style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            position: 'absolute',
                            marginTop: '50px',
                        }}>
                            <CircularProgress
                                size="lg"
                            />
                        </tr>
                        </tbody>
                    }
                </Table>


            </Sheet>
            <React.Fragment>
                <LeadsModal key={'leads-modal-' + openLeadModal} openState={openLeadModal}
                            leadId={openLeadId} parentCallback={handleCallback}/>
            </React.Fragment>
        </>
    );
}