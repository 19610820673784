import * as React from 'react';
import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Stack from '@mui/joy/Stack';
import ChatBubble from './ChatBubble';
import MessagesPaneHeader from './MessagesPaneHeader';
import MessageInput from './MessageInput';
import {useEffect, useState} from "react";
import {GetUser, PostSMSTextResponse} from "../../../axios/Axios";
import {
    GetAllServicePrices,
    GetLatestLeadByCustomerId,
} from "../../../axios/Bookings-Axios";
import {CircularProgress} from "@mui/joy";

type MessagesPaneProps = {
    chat: ChatProps;
};

interface User {
    username: string;
}

export default function MessagesPane(props: MessagesPaneProps) {
    const {chat} = props;
    const [loadedData, setLoadingData] = React.useState(true);
    const [chatMessages, setChatMessages] = React.useState(chat.messages);
    const [textAreaValue, setTextAreaValue] = React.useState('');
    const [user, setUser] = useState<User>({username: ''});
    const [lead, setLead] = React.useState <Lead>({
        created_at: "",
        customer: undefined,
        id: -1,
        notes: undefined,
        paid: false,
        services: [],
        service_price: [],
        status: []
    });
    const [leadValue, setLeadValue] = React.useState <ServiceValue>({
        conservatoryGutterCleanPrice: 0, gutterCleanPrice: 0, windowCleanPrice: 0,
        conservatoryGutterClean: false,
        gutterClean: false,
        price: 0,
        windowClean: false
    })
    const [seed, setSeed] = useState<number>(0);

    useEffect(() => {
        const newSeed = Math.random();
        setSeed(newSeed);
    }, [lead]);

    useEffect(() => {
        if (chatMessages.length == 0) {
            setLoadingData(false)
            return;
        }
        setLoadingData(true)
        const fetchData = async () => {
            const userResponse = await GetUser();
            setUser(userResponse.data);
            const lead = await GetLatestLeadByCustomerId(chatMessages[0].sender.id);
            setLead(lead)
            const servicePriceTier = await GetAllServicePrices('D', 'Detached');
            const defaultWindowCleanPrice = lead?.customer?.franchise_postcode?.service_price_tier?.window_clean_price
                || servicePriceTier[0].window_clean_price;

            const defaultConservatoryGutterCleanPrice = lead?.customer?.franchise_postcode?.service_price_tier?.conservatory_gutter_clean_price
                || servicePriceTier[0].conservatory_gutter_clean_price;

            const defaultGutterCleanPrice = lead?.customer?.franchise_postcode?.service_price_tier?.gutter_clean_price
                || servicePriceTier[0].gutter_clean_price;

            setLeadValue(prevLead => ({
                ...prevLead,
                gutterClean: lead.services.some(service => service.name === "Gutter Clean"),
                windowClean: lead.services.some(service => service.name === "Window Clean"),
                conservatoryGutterClean: lead.services.some(service => service.name === "Conservatory Gutter Clean"),
                conservatoryGutterCleanPrice: lead.service_price?.find(item => item.service.name === "Conservatory Gutter Clean")?.price || defaultConservatoryGutterCleanPrice,
                gutterCleanPrice: lead.service_price?.find(item => item.service.name === "Gutter Clean")?.price || defaultGutterCleanPrice,
                windowCleanPrice: lead.service_price?.find(item => item.service.name === "Window Clean")?.price || defaultWindowCleanPrice,
            }));
            setLoadingData(false)
        };
        fetchData()
    }, []);


    useEffect(() => {
        setChatMessages(chat.messages);
    }, [chat.messages]);

    function addChatMessage() {
        const newId = chatMessages.length + 1;
        const newIdString = newId.toString();
        let newChatMessage = {
            id: newIdString,
            sender: {
                id: '-1',
                name: user.username,
                phone: chat.sender.phone,
                isCustomer: false,
                username: user.username,
            },
            content: textAreaValue,
            timestamp: new Date().toISOString(),
        }
        setChatMessages([
            ...chatMessages,
            newChatMessage,
        ]);
        PostSMSTextResponse(newChatMessage)

    }

    return (
        <Sheet
            sx={{
                height: {xs: 'calc(90dvh - var(--Header-height))', md: '90dvh'},
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'background.level1',
            }}
        >
            {loadedData ? (
                <Box sx={{
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    minWidth: '50%',
                    height: '100%',
                    justifyContent: 'center'
                }}>
                    <CircularProgress
                        size="lg"
                    />
                </Box>
            ) : (
                <>

                    <MessagesPaneHeader sender={chat.sender}/>
                    <Box
                        sx={{
                            display: 'flex',
                            flex: 1,
                            minHeight: 0,
                            px: 2,
                            py: 3,
                            overflowY: 'scroll',
                            flexDirection: 'column-reverse',
                        }}
                    >
                        <Stack spacing={2} sx={{justifyContent: 'flex-end'}}>
                            {chatMessages
                                .sort((a: MessageProps, b: MessageProps) =>
                                    new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
                                )
                                .map((message: MessageProps, index: number) => {
                                    if (message.leadData || message.bookingData) {
                                    } else {
                                        const isYou = !message.sender.isCustomer;
                                        return (
                                            <Stack
                                                key={index}
                                                direction="row"
                                                spacing={2}
                                                sx={{flexDirection: isYou ? 'row-reverse' : 'row'}}
                                            >
                                                <ChatBubble variant={isYou ? 'sent' : 'received'} {...message} />
                                            </Stack>
                                        );
                                    }

                                })}
                        </Stack>
                    </Box>
                    <MessageInput
                        key={seed}
                        latestLead={lead}
                        latestLeadValue={leadValue}
                        messages={chatMessages}
                        textAreaValue={textAreaValue}
                        setTextAreaValue={setTextAreaValue}
                        onSubmit={() => addChatMessage()}
                    />
                </>
            )}
        </Sheet>
    );
}