import * as React from 'react';
import {Breadcrumbs, Card, CircularProgress, CssBaseline, CssVarsProvider, Link, Select, Textarea} from "@mui/joy";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import SideNav from "../dashboard-components/SideNav";
import Header from "../dashboard-components/Header";
import {useEffect, useState} from "react";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Stripe from "../dashboard-components/Stripe";
import {GetStripePayouts, GetToggledAds} from "../../axios/Axios";
import {ConnectComponentsProvider, ConnectPayouts, ConnectPayoutsList} from "@stripe/react-connect-js";
import {loadConnectAndInitialize} from "@stripe/connect-js";

interface Franchise {
    location: string;
    stripe_account_linked: boolean;
}

export default function Payouts() {
    const [franchise, setFranchise] = useState<Franchise>({
        stripe_account_linked: false,
        location: '',

    });
    let location = localStorage.getItem('location')?.toLowerCase().replace(' ', '_')
    const [loadedData, setLoadingData] = React.useState(true);
    const [stripeConnectInstance] = useState(() => {
        const fetchClientSecret = async () => {
            let token = await GetStripePayouts(location);
            return token['token']['client_secret']
        }

        return loadConnectAndInitialize({
            publishableKey: "pk_live_51OoZFZAsiu1ed7c1YKPHxo859668qe2if7w1douHxf067yDYzy2JN4CDAXm5JELuiSgFWFc3dwrLgJaQjPdYoRI300gsuh0TGx",
            fetchClientSecret: fetchClientSecret,
        })
    });

    useEffect(() => {
        setLoadingData(true)

        const getFranchise = async () => {
            let ads = await GetToggledAds(location);
            setFranchise(ads)

            setLoadingData(false)
        };
        getFranchise();

    }, [])

    return (
        <CssVarsProvider disableTransitionOnChange>
            <CssBaseline/>
            <ToastContainer/>
            <Box sx={{display: 'flex', minHeight: '100dvh'}}>
                <Header/>
                <SideNav/>
                <Box
                    component="main"
                    className="MainContent"
                    sx={{
                        px: {xs: 2, md: 6},
                        pt: {
                            xs: 'calc(12px + var(--Header-height))',
                            sm: 'calc(12px + var(--Header-height))',
                            md: 3,
                        },
                        pb: {xs: 2, sm: 2, md: 3},
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 0,
                        height: '100dvh',
                        gap: 1,
                    }}
                >
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Breadcrumbs
                            size="sm"
                            aria-label="breadcrumbs"
                            separator={<ChevronRightRoundedIcon/>}
                            sx={{pl: 0}}
                        >
                            <Link
                                underline="none"
                                color="neutral"
                                href="#some-link"
                                aria-label="Home"
                            >
                                <HomeRoundedIcon/>
                            </Link>
                            <Link
                                underline="hover"
                                color="neutral"
                                href="#some-link"
                                fontSize={12}
                                fontWeight={500}
                            >
                                Dashboard
                            </Link>
                            <Typography color="primary" fontWeight={500} fontSize={12}>
                                Payouts
                            </Typography>
                        </Breadcrumbs>
                    </Box>

                    {loadedData ? (
                        <Box sx={{
                            display: 'flex',
                            gap: 2,
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            minWidth: '50%',
                            height: '100%',
                            justifyContent: 'center'
                        }}>
                            <CircularProgress
                                size="lg"
                            />
                        </Box>
                    ) : (
                        <>

                            <Box
                                sx={{
                                    display: 'flex',
                                    mb: 1,
                                    gap: 1,
                                    flexDirection: {xs: 'column', sm: 'row'},
                                    alignItems: {xs: 'start', sm: 'center'},
                                    flexWrap: 'wrap',
                                    justifyContent: 'space-between',
                                }}
                            >

                                <Typography level="h2" component="h1">
                                    Payouts
                                </Typography>

                            </Box>
                            {!franchise.stripe_account_linked ? (
                                <Stripe franchise={franchise}/>
                            ) : (

                                <>
                                    <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
                                        <ConnectPayouts/>
                                    </ConnectComponentsProvider>
                                </>
                            )}
                        </>

                    )}

                </Box>
            </Box>
        </CssVarsProvider>
    )

}