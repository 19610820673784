import React, {useEffect} from "react";
import {
    CircularProgress,
    ListItemDecorator,
    Modal,
    ModalClose,
    ModalDialog,
    Radio,
    RadioGroup,
    Select,
    Textarea
} from "@mui/joy";
import Box from "@mui/joy/Box";
import {
    CreateBooking, DeleteCustomerById, DeleteLeadById,
    GetAllBookings,
    GetAllServicePrices,
    GetBookingById, GetCustomerById, GetLeadById,
    UpdateBooking, UpdateCustomer
} from "../../axios/Bookings-Axios";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Option from "@mui/joy/Option";
import Typography from "@mui/joy/Typography";
import 'react-datepicker/dist/react-datepicker.css';
import Button from "@mui/joy/Button";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import {DeleteForever} from "@mui/icons-material";
import CustomersTopComponent from "./CustomersTopComponent";

export default function CustomersModal({openState, customerId, parentCallback}) {
    const [open, setOpen] = React.useState(openState);
    const [loadedData, setLoadedData] = React.useState(false);
    const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean>(false);

    const [customer, setCustomer] = React.useState <Customer>({
        advertisement_platform: undefined,
        created_at: "",
        email: "",
        full_name: "",
        house_type: undefined,
        id: 0,
        notes: "",
        phone: "",
        postcode: "",
        street_address: ""
    });


    const handleCustomerInputChange = (event) => {
        const {name, value} = event.target;
        setCustomer(prevCustomer => ({
            ...(prevCustomer || {}),
            [name]: value
        }));

    };

    const handleCustomerHouseTypeChange = (newValue: string | null) => {
        if (newValue !== null) {
            setCustomer(prevCustomer => ({
                ...prevCustomer,
                house_type: {name: newValue, id: -1}
            }));
        }
    };


    useEffect(() => {
        if (customerId === 0) {
            return;
        }
        setLoadedData(false)
        const fetchData = async () => {
            let customer = await GetCustomerById(customerId);
            if (customer.notes == null) {
                customer.notes = ''
            }
            setCustomer(customer)
            setLoadedData(true)
        };
        fetchData()
    }, []);


    function closeModal() {
        setOpen(false)
        parentCallback();
    }

    let handleDeleteCallBack = async (doDelete) => {
        setOpenDeleteModal(false)
        if (!doDelete) {
            return;
        }
        const deleteLead = async () => {
            return await DeleteCustomerById(customerId);
        }
        await deleteLead();
        window.location.reload(false);
    };

    const handleSave = async (event) => {
        event.preventDefault()
        const updateCustomer = async () => {
            let customerRes = await UpdateCustomer(customerId, customer);
            return customerRes
        };
        await updateCustomer();
        window.location.reload(false);

    };

    return (
        <>
            <Modal slotProps={{
                backdrop: {
                    sx: {
                        backdropFilter: 'none',
                        backgroundColor: 'rgb(108 108 108 / 60%)',
                        padding: '10px'
                    },
                },
            }} open={open} onClose={() => closeModal()}>
                <ModalDialog sx={{overflowY: 'scroll'}}>
                    <ModalClose/>
                    <Box sx={{
                        minWidth: 600,
                        display: 'flex',
                        flexDirection: 'column',
                        overflowX: 'hidden',
                    }}>
                    </Box>

                    {!loadedData ?
                        <Box sx={{
                            display: 'flex',
                            gap: 2,
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            minWidth: '50%',
                            height: '100%',
                            justifyContent: 'center'
                        }}>
                            <CircularProgress
                                size="lg"
                            />
                        </Box>
                        :
                        <form onSubmit={handleSave} id="a-form">
                            <CustomersTopComponent customer={customer} setCustomer={setCustomer}/>
                        </form>

                    }

                    <Box sx={{display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
                        {loadedData && (
                            <Button
                                sx={{marginLeft: 'auto'}}
                                color="danger"
                                endDecorator={<DeleteForever/>}
                                onClick={() => setOpenDeleteModal(true)}
                            >
                                Delete
                            </Button>
                        )}
                        {loadedData && customerId !== -1 && (
                            <Button form="a-form" type="submit" sx={{marginLeft: '10px'}}>Save
                                Customer</Button>
                        )}
                        {loadedData && customerId === -1 && (
                            <Button form="a-form" type="submit" sx={{marginLeft: 'auto'}}>Create New
                                Customer</Button>
                        )}
                    </Box>
                </ModalDialog>
            </Modal>
            <React.Fragment>
                <ConfirmDeleteModal discardMessage={"Are you sure you want to delete this customer?"}
                                    openState={openDeleteModal}
                                    parentCallback={handleDeleteCallBack}></ConfirmDeleteModal>
            </React.Fragment>
        </>
    )
}