import * as React from 'react';
import {
    Breadcrumbs,
    Card, Chip, CircularProgress,
    CssBaseline,
    CssVarsProvider, FormHelperText,
    Link, Radio, RadioGroup
} from "@mui/joy";
import Box from "@mui/joy/Box";
import Header from "../dashboard-components/Header";
import SideNav from "../dashboard-components/SideNav";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import Typography from "@mui/joy/Typography";
import {GetToggledAds, IsLastPaymentSuccess, PostRecurringStatus, SetFranchiseAdStatus} from "../../axios/Axios";
import {toast, ToastContainer} from "react-toastify";
import {useEffect, useState} from "react";
import Stripe from "../dashboard-components/Stripe";
import StripeLeadPaymentModal from "../modals/StripeLeadPaymentModal";
import Button from "@mui/joy/Button";
import {InsertLink, PaymentSharp} from "@mui/icons-material";
import StripeBalanceTopUpModal from "../modals/StripeBalanceTopUpModal";
import Tooltip from "@mui/joy/Tooltip";

interface Franchise {
    location: string;
    gutter_ads_status: string;
    window_ads_status: string;
    roof_clean_ads_status: string;
    stripe_account_linked: boolean;
    recurring_leads_purchase: boolean;
    lead_balance: number;
    payment_invoice_url: string;
    stripe_account_id: string;
    stripe_customer_account_id: string;
    stripe_customer_payment_method_linked: boolean;
}

export default function LeadGeneration() {
    const [franchise, setFranchise] = useState<Franchise>({
        stripe_account_linked: false,
        stripe_customer_payment_method_linked: false,
        recurring_leads_purchase: false,
        lead_balance: 0,
        stripe_account_id: '',
        location: '',
        stripe_customer_account_id: '',
        gutter_ads_status: '',
        roof_clean_ads_status: '',
        window_ads_status: '',
        payment_invoice_url: '',
    });

    let location = localStorage.getItem('location')?.toLowerCase().replace(' ', '_')
    const [loadedData, setLoadingData] = React.useState(true);
    const [selectedAds, setSelectAds] = React.useState('residential_ads');
    const [toggleOffReason, setToggleOffReason] = React.useState<String>('');
    const [selectedAdsToggle, setSelectAdsToggle] = React.useState<String>('');
    const [openStripeModal, setOpenStripeModal] = React.useState<boolean>(false);
    const [openBalanceTopUpModal, setOpenBalanceTopUpModal] = React.useState<boolean>(false);
    const [lastPaymentSuccess, setLastPaymentSuccess] = useState<boolean>(true);
    const [paymentLink, setPaymentLink] = useState<string>('');


    useEffect(() => {
        const loadIsPaymentSuccess = async () => {
            let location = localStorage.getItem('location')?.toLowerCase().replace(' ', '_')
            let success = await IsLastPaymentSuccess(location)
            setLastPaymentSuccess(success['success'])
            setPaymentLink(success['failed_payment_invoice_url'])
        }
        loadIsPaymentSuccess()
    }, [])

    function createPaymentLink() {
        window.location.replace(paymentLink);
    }

    useEffect(() => {

        const getToggledAds = async () => {
            let data = await GetToggledAds(location);
            setFranchise(data)
            setLoadingData(false)
        };
        getToggledAds();

    }, [])


    function handleCallback(field, on, toggleOffReason?) {
        setOpenStripeModal(false)
        setOpenBalanceTopUpModal(false)
        if (field != '') {
            if (on) {
                SetFranchiseAdStatus(location, field, 'Pending')
                if (field == 'window_clean') {
                    setFranchise(prevFranchise => ({
                        ...prevFranchise,
                        window_ads_status: 'Pending'
                    }));
                    setSelectAdsToggle('Pending')
                } else if (field == 'gutter_clean') {
                    setFranchise(prevFranchise => ({
                        ...prevFranchise,
                        gutter_ads_status: 'Pending'
                    }));
                    setSelectAdsToggle('Pending')
                }
            } else {
                SetFranchiseAdStatus(location, field, 'Off', toggleOffReason)
                if (field == 'window_clean') {
                    setFranchise(prevFranchise => ({
                        ...prevFranchise,
                        window_ads_status: 'Off'
                    }));
                    setSelectAdsToggle('Off')
                } else if (field == 'gutter_clean') {
                    setFranchise(prevFranchise => ({
                        ...prevFranchise,
                        gutter_ads_status: 'Off'
                    }));
                    setSelectAdsToggle('Off')
                }
            }
        }

    }

    const handleChange = (event) => {
        const value = event.target.value;
        handlePaymentOption(value);
    };

    const handlePaymentOption = (value) => {
        const postRecurringStatus = async () => {
            let status = 'off'
            if (value == 'recurring') {
                status = 'on'
            }
            let response = PostRecurringStatus(location, value)
            toast.success(`Toggled recurring status ${status}!`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
        postRecurringStatus()
    };


    function toggleAd(field) {
        if (franchise.window_ads_status == 'Pending' || franchise.gutter_ads_status == 'Pending') {
            window.location.replace(franchise.payment_invoice_url)
            return;
        }
        if (field == 'window_clean') {

            setSelectAdsToggle(franchise.window_ads_status)
        } else if (field == 'gutter_clean') {

            setSelectAdsToggle(franchise.gutter_ads_status)
        }

        setSelectAds(field)
        setOpenStripeModal(true)
    }

    return (
        <CssVarsProvider disableTransitionOnChange>

            <React.Fragment>
                <StripeLeadPaymentModal selectedAdToggled={selectedAdsToggle} selectedAd={selectedAds}
                                        franchise={franchise}
                                        parentCallback={handleCallback}
                                        openState={openStripeModal}></StripeLeadPaymentModal>
            </React.Fragment>

            <React.Fragment>
                <StripeBalanceTopUpModal
                    franchise={franchise}
                    parentCallback={handleCallback}
                    openState={openBalanceTopUpModal}></StripeBalanceTopUpModal>
            </React.Fragment>

            <CssBaseline/>
            <ToastContainer/>
            <Box sx={{display: 'flex', minHeight: '100dvh'}}>
                <Header/>
                <SideNav/>
                <Box
                    component="main"
                    className="MainContent"
                    sx={{
                        px: {xs: 2, md: 6},
                        pt: {
                            xs: 'calc(12px + var(--Header-height))',
                            sm: 'calc(12px + var(--Header-height))',
                            md: 3,
                        },
                        pb: {xs: 2, sm: 2, md: 3},
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 0,
                        height: '100dvh',
                        gap: 1,
                    }}
                >
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Breadcrumbs
                            size="sm"
                            aria-label="breadcrumbs"
                            separator={<ChevronRightRoundedIcon/>}
                            sx={{pl: 0}}
                        >
                            <Link
                                underline="none"
                                color="neutral"
                                href="#some-link"
                                aria-label="Home"
                            >
                                <HomeRoundedIcon/>
                            </Link>
                            <Link
                                underline="hover"
                                color="neutral"
                                href="#some-link"
                                fontSize={12}
                                fontWeight={500}
                            >
                                Dashboard
                            </Link>

                            <Typography color="primary" fontWeight={500} fontSize={12}>
                                Lead Generation
                            </Typography>
                        </Breadcrumbs>
                    </Box>

                    {loadedData ? (
                        <Box sx={{
                            display: 'flex',
                            gap: 2,
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            minWidth: '50%',
                            height: '100%',
                            justifyContent: 'center'
                        }}>
                            <CircularProgress
                                size="lg"
                            />
                        </Box>
                    ) : (

                        <>
                            {!franchise.stripe_account_linked ? (
                                <Stripe franchise={franchise}/>
                            ) : (
                                <>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            mb: 1,
                                            gap: 0.5,
                                            flexDirection: {xs: 'column', sm: 'column'},
                                            flexWrap: 'wrap',
                                        }}
                                    >


                                        <Typography level="h2" component="h1">
                                            Lead Generation
                                        </Typography>
                                        <Typography sx={{marginBottom: '25px'}} level="title-lg">
                                            Your Balance: £{franchise.lead_balance}.0
                                            <Tooltip
                                                title={franchise.payment_invoice_url !== '' ? "Top-up is disabled because there is already an outstanding invoice." : ''}
                                                disableHoverListener={franchise.payment_invoice_url === ''}
                                            >
                                            <span>
                                                <Button
                                                    size="sm"
                                                    onClick={() => setOpenBalanceTopUpModal(true)}
                                                    startDecorator={<InsertLink/>}
                                                    variant="outlined"
                                                    color="primary"
                                                    sx={{marginLeft: '10px'}}
                                                    disabled={franchise.payment_invoice_url !== ''}
                                                >
                                                    Top Up
                                                </Button>
                                            </span>
                                            </Tooltip>
                                        </Typography>

                                        {franchise.stripe_customer_payment_method_linked && (
                                            <RadioGroup style={{width: '350px', display: 'flex', marginBottom: '25px'}}
                                                        defaultValue={franchise.recurring_leads_purchase ? "recurring" : "one-time"}
                                                        onChange={handleChange}
                                                        name="radio-buttons-group">
                                                <Radio
                                                    value="one-time"
                                                    label="One Time Payment"
                                                    slotProps={{input: {'aria-describedby': 'one-time-helper-text'}}}
                                                />
                                                <Typography style={{marginLeft: '35px', marginTop: '10px'}}
                                                            id="one-time-helper-text"
                                                            level="body-sm" color="neutral">
                                                    Your ads will <span
                                                    style={{fontWeight: '500'}}>TURN OFF</span> after
                                                    your balance has ran out.
                                                </Typography>
                                                <Radio
                                                    value="recurring"
                                                    label="Recurring Payment"
                                                    slotProps={{input: {'aria-describedby': 'recurring-helper-text'}}}
                                                />
                                                <Typography style={{marginLeft: '35px', marginTop: '10px'}}
                                                            id="one-time-helper-text"
                                                            level="body-sm" color="neutral">
                                                    We will attempt to automatically charge your saved card once your
                                                    balance runs out to keep your ads running (You will be charged every
                                                    5 leads)
                                                </Typography>
                                            </RadioGroup>
                                        )}

                                    </Box>
                                    {!lastPaymentSuccess ? (
                                        <Card sx={{
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                            textAlign: 'center'
                                        }}>
                                            <Typography level={"title-md"}>Your ads are
                                                turned OFF as your last payment
                                                didnt
                                                go
                                                through</Typography>
                                            <Button onClick={() => {
                                                createPaymentLink()
                                            }} sx={{marginTop: '5px'}}>Click here to confirm payment</Button>
                                        </Card>
                                    ) : (

                                        <Box sx={{display: 'flex', width: '100%'}}>
                                            <Card orientation="horizontal" size="lg"

                                                  sx={{
                                                      cursor: 'pointer',
                                                      justifyContent: 'space-between',
                                                      width: '100%',
                                                      marginRight: 2
                                                  }}
                                                  onClick={(event) => {
                                                      toggleAd('window_clean')
                                                  }}>
                                                <Box>
                                                    <Typography level="title-lg">Window Cleaning Ads <Typography
                                                        color="neutral"
                                                        level="body-xs">(Google &
                                                        Facebook)</Typography></Typography>
                                                    <Typography level="body-md">£15.00 per lead</Typography>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: 2,
                                                        height: '50px',
                                                        '& > *': {
                                                            height: '50px',
                                                            padding: '0 15px',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        },
                                                    }}
                                                >

                                                    {franchise.window_ads_status != 'Pending' &&
                                                        <Button sx={{padding: '15px'}}>Click Here</Button>
                                                    }
                                                    {franchise.window_ads_status === 'On' &&
                                                        <Chip color="success" sx={{padding: '15px'}}>On</Chip>}
                                                    {franchise.window_ads_status === 'Off' &&
                                                        <Chip color="danger" sx={{padding: '15px'}}>Off</Chip>}
                                                    {franchise.window_ads_status === 'Pending' && (
                                                        <Chip
                                                            endDecorator={<Button size="sm" variant="plain"
                                                                                  color="warning"
                                                                                  loading={true}/>}
                                                            color="warning"

                                                            sx={{padding: '15px'}}
                                                        >Pending Payment<Typography level={"body-xs"} color={"neutral"}>(Click
                                                            Here)</Typography></Chip>
                                                    )}
                                                </Box>
                                            </Card>
                                            <Card orientation="horizontal" size="lg"
                                                  sx={{
                                                      cursor: 'pointer',
                                                      justifyContent: 'space-between',
                                                      width: '100%',
                                                      marginRight: 2
                                                  }}
                                                  onClick={(event) => {
                                                      toggleAd('gutter_clean')
                                                  }}>
                                                <Box>
                                                    <Typography level="title-lg">Gutter Cleaning Ads <Typography
                                                        color="neutral"
                                                        level="body-xs">(Google &
                                                        Facebook)</Typography></Typography>
                                                    <Typography level="body-md">£15.00 per lead</Typography>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: 2,
                                                        height: '50px',
                                                        '& > *': {
                                                            height: '50px',
                                                            padding: '0 15px',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        },
                                                    }}
                                                >
                                                    {franchise.gutter_ads_status != 'Pending' &&
                                                        <Button sx={{padding: '15px'}}>Click Here</Button>
                                                    }
                                                    {franchise.gutter_ads_status === 'On' &&
                                                        <Chip color="success" sx={{padding: '15px'}}>On</Chip>}
                                                    {franchise.gutter_ads_status === 'Off' &&
                                                        <Chip color="danger" sx={{padding: '15px'}}>Off</Chip>}
                                                    {franchise.gutter_ads_status === 'Pending' && (
                                                        <Chip
                                                            endDecorator={<Button size="sm" variant="plain"
                                                                                  color="warning"
                                                                                  loading={true}/>}
                                                            color="warning"

                                                            sx={{padding: '15px'}}
                                                        >Pending Payment<Typography level={"body-xs"} color={"neutral"}>(Click
                                                            Here)</Typography></Chip>
                                                    )}
                                                </Box>
                                            </Card>

                                        </Box>
                                    )}

                                </>
                            )}
                        </>
                    )}


                </Box>
            </Box>


        </CssVarsProvider>
    )

}