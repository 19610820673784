import * as React from 'react';
import IconButton from '@mui/joy/IconButton';
import Table from '@mui/joy/Table';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {useEffect, useState} from "react";
import {GetAllCustomers} from "../../axios/Bookings-Axios";
import Button from "@mui/joy/Button";
import Box from "@mui/joy/Box";
import {Close, Done, KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import {Chip, CircularProgress, Select} from "@mui/joy";
import Input from "@mui/joy/Input";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import {useSearchParams} from "react-router-dom";
import BookingsModal from "../modals/BookingsModal";
import CustomersModal from "../modals/CustomersModal";
import DebounceInput from "../../domain/DebounceInput";

interface Row {
    full_name: string,
    street_address: string,
    postcode: string,
    phone: string,
    house_type: string,
    id: string,
    email: string,
    expanded_row: RowExpanded
}

interface RowExpanded {
    notes: string,
}


function Row(props: { row: Row; initialOpen?: boolean, onRowClick: (rowId: number) => void }) {
    const {row} = props;
    const [open, setOpen] = React.useState(props.initialOpen || false);

    const handleClick = () => {
        props.onRowClick(Number(row.id));
    };

    return (
        <React.Fragment>
            <tr style={{width: '100%'}}>
                <td>
                    <IconButton
                        aria-label="expand row"
                        variant="plain"
                        color="neutral"
                        size="sm"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </td>
                <td>{row.full_name}</td>
                <td>{row.phone}</td>
                <td>{row.email}</td>
                <td>{row.street_address}</td>
                <td>{row.postcode}</td>
                <td>
                    <Button size="sm" color="primary" onClick={handleClick}>
                        Edit Customer
                    </Button>
                </td>

            </tr>
            <tr>
                <td style={{height: 0, padding: 0, width: '100%'}} colSpan={7}>
                    {open && (
                        <Sheet
                            variant="soft"
                            sx={{p: 1, pl: 6, boxShadow: 'inset 0 3px 6px 0 rgba(0 0 0 / 0.08)'}}
                        >
                            <Table
                                borderAxis="bothBetween"
                                aria-label="purchases"
                                sx={{
                                    '& > thead > tr > th:nth-child(n + 3), & > tbody > tr > td:nth-child(n + 3)':
                                        {textAlign: 'right'},
                                    '--TableCell-paddingX': '0.5rem',
                                }}
                            >
                                <thead>
                                <tr>
                                    <th>Notes</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td style={{whiteSpace: 'pre'}}>{row.expanded_row.notes !== '' ? row.expanded_row.notes : 'No notes'}</td>
                                </tr>
                                </tbody>
                            </Table>
                        </Sheet>
                    )}
                </td>
            </tr>

        </React.Fragment>
    );
}

const mapRawDataToRow = (data: any[]): Row[] => {
    return data.map(item => {
        return {
            full_name: item.full_name,
            street_address: item.street_address,
            postcode: item.postcode,
            phone: item.phone,
            house_type: item.house_type || '',
            id: item.id.toString(),
            email: item.email,
            expanded_row: {
                notes: item.notes,
            }
        };
    });
};

function labelDisplayedRows({
                                from,
                                to,
                                count,
                            }: {
    from: number;
    to: number;
    count: number;
}) {
    return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
}

export default function CustomersTable() {
    const [loadedData, setLoadingData] = React.useState(false);
    const [rows, setRows] = React.useState <Row[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [pageCount, setPageCount] = React.useState(0);
    const [search, setSearch] = React.useState('');
    const [urlSearchParam, setUrlSearchParam] = useSearchParams();

    const [openCustomerId, setOpenCustomerId] = React.useState(0);
    const [openCustomerModal, setOpenCustomerModal] = React.useState(false);


    let handleCallback = () => {
        setOpenCustomerModal(false)
    };

    const handleOpenCustomerModal = (rowId) => {
        setOpenCustomerModal(true)
        setOpenCustomerId(rowId)
    };

    const getLabelDisplayedRowsTo = () => {
        if (pageCount === -1) {
            return (page + 1) * rowsPerPage;
        }
        return rowsPerPage === -1
            ? pageCount
            : Math.min(pageCount, (page + 1) * rowsPerPage);
    };

    const handleChangePage = (newPage: number) => {
        setPage(newPage);
    };

    useEffect(() => {
        if (!loadedData) {
            return
        }
        setLoadingData(false)
        const fetchData = async () => {
            let location = localStorage.getItem('location')?.toLowerCase().replace(' ', '_')
            let customers = await GetAllCustomers([location], page + 1, search);
            setRows(mapRawDataToRow(customers['results']))
            setPageCount(customers['count'])
            setLoadingData(true)
        };
        fetchData()
    }, [page, search]);

    useEffect(() => {
        setLoadingData(false)

        const fetchData = async () => {
            let location = localStorage.getItem('location')?.toLowerCase().replace(' ', '_')
            let customers = await GetAllCustomers([location], page + 1, search);
            setRows(mapRawDataToRow(customers['results']))
            setPageCount(customers['count'])

            setLoadingData(true)
        };
        fetchData()
    }, []);


    const handleDebounce = (value: string) => {
        setSearch(value);
    };

    return (
        <>
            <Box>
                <DebounceInput sx={{width: '15%'}} startDecorator={<SearchRoundedIcon/>}
                               placeholder="Search by any field..."
                               debounceTimeout={1000}
                               defaultValue={urlSearchParam.get("lead-id") as string}
                               handleDebounce={handleDebounce}
                />
            </Box>
            <Sheet>

                <Table
                    hoverRow
                    aria-label="collapsible table"
                    sx={{
                        height: '100%',
                        width: '100%',
                        '& > thead > tr > th:nth-child(n + 3), & > tbody > tr > td:nth-child(n + 3)':
                            {textAlign: 'right'},
                        '& > tbody > tr:nth-child(odd) > td, & > tbody > tr:nth-child(odd) > th[scope="row"]':
                            {
                                borderBottom: 0,
                            },
                    }}
                >
                    <thead>
                    <tr style={{width: '100%'}}>
                        <th style={{width: 40}} aria-label="empty"/>
                        <th>Full Name</th>
                        <th>Number</th>
                        <th>Email</th>
                        <th>Street Address</th>
                        <th>Postcode</th>
                        <th aria-label="last"></th>
                    </tr>
                    </thead>
                    {loadedData ?
                        <>
                            <tbody>
                            {rows.map((row, index) => (
                                <Row key={row.id} row={row} initialOpen={search.length !== 0}
                                     onRowClick={handleOpenCustomerModal}/>
                            ))}
                            </tbody>
                            <tfoot>
                            <tr>
                                <td colSpan={7}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 2,
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        <Typography textAlign="center" sx={{minWidth: 80}}>
                                            {labelDisplayedRows({
                                                from: pageCount === 0 ? 0 : page * rowsPerPage + 1,
                                                to: getLabelDisplayedRowsTo(),
                                                count: pageCount === -1 ? -1 : pageCount,
                                            })}
                                        </Typography>
                                        <Box sx={{display: 'flex', gap: 1}}>
                                            <IconButton
                                                size="sm"
                                                color="neutral"
                                                variant="outlined"
                                                disabled={page === 0}
                                                onClick={() => handleChangePage(page - 1)}
                                                sx={{bgcolor: 'background.surface'}}
                                            >
                                                <KeyboardArrowLeft/>
                                            </IconButton>
                                            <IconButton
                                                size="sm"
                                                color="neutral"
                                                variant="outlined"
                                                disabled={
                                                    pageCount !== -1
                                                        ? page >= Math.ceil(pageCount / rowsPerPage) - 1
                                                        : false
                                                }
                                                onClick={() => handleChangePage(page + 1)}
                                                sx={{bgcolor: 'background.surface'}}
                                            >
                                                <KeyboardArrowRight/>
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </td>
                            </tr>
                            </tfoot>
                        </>
                        :
                        <tbody>
                        <tr style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            position: 'absolute',
                            marginTop: '50px',
                        }}>
                            <CircularProgress
                                size="lg"
                            />
                        </tr>
                        </tbody>
                    }
                </Table>

            </Sheet>
            <React.Fragment>
                <CustomersModal key={'customer-modal-' + openCustomerModal} openState={openCustomerModal}
                                customerId={openCustomerId} parentCallback={handleCallback}/>
            </React.Fragment>
        </>


    );
}