import * as React from 'react';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import {toggleMessagesPane} from "./MessageUtils";

type MessagesPaneHeaderProps = {
    sender: UserProps;
};

export default function MessagesPaneHeader(props: MessagesPaneHeaderProps) {
    const {sender} = props;
    return (
        <Stack
            direction="row"
            sx={{
                justifyContent: 'space-between',
                py: {xs: 2, md: 2},
                px: {xs: 1, md: 2},
                borderBottom: '1px solid',
                borderColor: 'divider',
                backgroundColor: 'background.body',
            }}
        >
            <Stack
                direction="row"
                spacing={{xs: 1, md: 2}}
                sx={{alignItems: 'center'}}
            >
                <IconButton
                    variant="plain"
                    color="neutral"
                    size="sm"
                    sx={{display: {xs: 'inline-flex', sm: 'none'}}}
                    onClick={() => toggleMessagesPane()}
                >
                    <ArrowBackIosNewRoundedIcon/>
                </IconButton>
                <div>
                    <Typography
                        component="h2"
                        noWrap
                        sx={{fontWeight: 'lg', fontSize: 'lg'}}
                    >
                        {sender.name}
                    </Typography>
                    <Typography level="body-sm">{sender.phone}</Typography>
                </div>
            </Stack>

        </Stack>
    );
}